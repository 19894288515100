<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiTagOutline" />
            </div>

            <div class="cs-card__title">
                Tag
            </div>

            <div class="flex-grow"></div>

            <div class="cs-card__icon-r" title="Edit Tag" @click="editTag">
                <mdi-icon icon="mdiPencil" />
            </div>
        </template>

        <div>
            <table class="cs-property-table">
                <tbody>
                    <tr
                        v-for="(value, key, index) in displayedProperties"
                        :key="index"
                        class="cs-property-table__line"
                        :class="index % 2 ? 'cs-property-table__line--odd' : 'cs-property-table__line--even'"
                    >
                        <th class="cs-property-table__label">
                            {{ key }}
                        </th>

                        <!-- eslint-disable vue/no-v-html -->
                        <td class="cs-property-table__value" v-html="value"></td>
                        <!-- eslint-enable vue/no-v-html -->
                    </tr>
                </tbody>
            </table>
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { clone } from 'lodash-es';
import CsCard    from '@/components/CsCard.vue';

export default
{
    name: 'TagCard',

    components:
    {
        CsCard,
    },

    props:
    {
        tag:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        displayedProperties()
        {
            const tag = clone(this.tag);

            return {
                'ID':          tag.id,
                'Label':       tag.label,
                'Code':        tag.code,
                'Description': tag.description?.replace(/\n/g, '<br>') || '',
            };
        },
    },

    methods:
    {
        /**
         * Open the "Edit Tag" form.
         */
        editTag()
        {
            this.$router.push({ name: 'edit-tag', params: { tag_id: this.$route.params.tag_id } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.cs-property-table__label
{
    width: 110px;
}
</style>
