<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div v-if="!loading" id="tag-details">
        <!-- Tag Card (summary) -->
        <tag-card :tag="tag" class="float-left pr-4 pb-4 w-full lg:w-1/2 3xl:w-1/3" />

        <div class="clear-both"></div>
    </div>

    <div v-else class="text-center">
        <img src="/img/loader-761.svg" alt="Loading..." class="inline">
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import TagCard from '@/components/tags/TagCard.vue';

export default
{
    name: 'TagDetails',

    components:
    {
        TagCard,
    },

    data()
    {
        return {
            loading: true,
        };
    },

    computed:
    {
        tag()
        {
            return this.$store.state.tags.cTag;
        },
    },

    watch:
    {
        '$route.params.tag_id'(tagId)
        {
            if(parseInt(tagId))
            {
                this.initialize();
            }
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            // Load the tag details
            this.loading = true;

            this.$store.dispatch('tags/fetchTag', this.$route.params.tag_id)
                .then(tag =>
                {
                    // Select the tag for display
                    this.$store.dispatch('tags/setCurrentTag', tag);
                })
                .catch(error =>
                {
                    // nothing
                })
                .then(() =>
                {
                    this.loading = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
#tag-details .cs-card .no-content
{
    @apply text-gray-400 italic;
}
</style>
