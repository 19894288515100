/* ====================
 *  Permissions Module
 * ====================
 */

import axios from '@/axios';

export default
{
    namespaced: true,


    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            all: [], // All permissions
        };
    },


    // ------------------------------------------------------------------------- GETTERS

    getters:
    {
    },


    // ------------------------------------------------------------------------- MUTATIONS

    mutations:
    {
        setAll(state, permissions)
        {
            state.all = permissions;
        },
    },


    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        /**
         * Retrieve the list of permissions and store it.
         * @returns {Promise}
         */
        fetchAll({ commit })
        {
            return new Promise((resolve, reject) =>
            {
                axios.get('/api/article-permission')
                    .then(response =>
                    {
                        commit('setAll', response.data);
                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        console.error(error);
                        reject(error);
                    });
            });
        },
    },
};
