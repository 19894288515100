// ------------------------------------------------------------ VUE

import { createApp } from 'vue';


// ------------------------------------------------------------ APP CONSTANTS

import constants from './constants/constants';


// ------------------------------------------------------------ STYLE

import './assets/styles/main.scss';


// ------------------------------------------------------------ V-CALENDAR

import '../node_modules/v-calendar/dist/style.css';


// ------------------------------------------------------------ PERFECT-SCROLLBAR

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';


// ------------------------------------------------------------ HONEYBADGER

import { createHoneybadger } from './utils/vue-honeybadger';

const VueHoneybadger = createHoneybadger({
    apiKey: '2788d99a',

    // By default, HoneyBadger only reports in the "production" environment
    environment: import.meta.env.MODE,
});


// ------------------------------------------------------------ VUE-CONFIGURATIONS

import { createVueConfigurations } from 'vue-configurations';

const VueApplicationConfigurations = createVueConfigurations();
const VueUserConfigurations = createVueConfigurations();


// ------------------------------------------------------------ STORE

import { setupStore } from './store';

const store = setupStore(VueApplicationConfigurations, VueUserConfigurations, VueHoneybadger);


// ------------------------------------------------------------ ROUTER

import { setupRouter } from './router';

const router = setupRouter(store);


// ------------------------------------------------------------ COMPONENTS

import MdiIcon from '@/components/MdiIcon.vue';


// ------------------------------------------------------------ LAUSANNE COMPONENTS

import {
    LsnCheckboxBox,
    LsnClickOutside,
    LsnIcon,
    LsnInput,
    LsnInputNumber,
    LsnModal,
    LsnRadioBox,
    LsnSelect,
    LsnSider,
    LsnSwitch,
    LsnTextarea,
} from 'vue-lausanne';


// ------------------------------------------------------------ APP AND STORE

import App from './App.vue';


// ------------------------------------------------------------ APP BUILDING

store.dispatch('auth/check').finally(() =>
{
    const app = createApp(App);

    // Register global constants as `$const`
    app.config.globalProperties.$const = constants;

    app
        .use(store)
        .use(router)
        .use(VueHoneybadger)
        .use(PerfectScrollbar)
        .use(VueApplicationConfigurations, { globalPropertyKey: '$aconfig' })
        .use(VueUserConfigurations, { globalPropertyKey: '$uconfig' })
        .directive('click-outside', LsnClickOutside)
        .component('LsnCheckboxBox', LsnCheckboxBox)
        .component('LsnIcon', LsnIcon)
        .component('LsnInput', LsnInput)
        .component('LsnInputNumber', LsnInputNumber)
        .component('LsnModal', LsnModal)
        .component('LsnRadioBox', LsnRadioBox)
        .component('LsnSelect', LsnSelect)
        .component('LsnSider', LsnSider)
        .component('LsnSwitch', LsnSwitch)
        .component('LsnTextarea', LsnTextarea)
        .component('MdiIcon', MdiIcon);

    // Save asked URL to redirect to it after initialization.
    let redirectTo = window.location.pathname;
    let queryParams = new URLSearchParams(window.location.search);
    let query = {};

    queryParams.forEach((value, key) =>
    {
        query[key] = value;
    });

    query.redirect = redirectTo;

    let route = router.resolve(window.location.pathname);
    let params = {};

    if(route.name == 'login' || route.name == 'initialize')
    {
        delete query.redirect;
    }

    if(!store.state.auth.authenticated)
    {
        router.push({ name: 'login', params: params, query: query });
    }
    else
    {
        router.push({ name: 'initializing', params: params, query: query });
    }

    router.isReady().then(() =>
    {
        const _app = app.mount('#app');

        if(import.meta.env.MODE !== 'production')
        {
            // Make the app instance globally available in development environment
            window.app = _app;
        }
    });
});
