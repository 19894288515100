/* ==============
 * Article Module
 * ==============
 */

import { sortBy } from 'lodash-es';
import axios      from '@/axios';

const helpers = {
    formatArticle(article)
    {
        if(article.photo_id !== null)
        {
            article.photo_id = parseInt(article.photo_id) || null;
        }
    },
};

export default
{
    namespaced: true,


    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            all:      [],    // All articles
            cArticle: null,  // Currently displayed article
        };
    },


    // ------------------------------------------------------------------------- GETTERS

    getters:
    {
        sortedArticles: state => sortBy(state.all, ['status', 'label']),

        oneById: state => id => state.all.find(a => a.id == id),
    },


    // ------------------------------------------------------------------------- MUTATIONS

    mutations:
    {
        setAll(state, articles)
        {
            for(const article of articles)
            {
                helpers.formatArticle(article);
            }

            state.all = articles;
        },

        /**
         * Add an article to articles list.
         * @param {Object} state
         * @param {Object} article
         */
        addToArticlesList(state, article)
        {
            helpers.formatArticle(article);
            state.all.push(article);
        },

        /**
         * Update an article in articles list.
         * @param {Object} state
         * @param {Object} article
         */
        updateInArticlesList(state, article)
        {
            const index = state.all.findIndex(a => a.id == article.id);
            if(index === -1)
            {
                throw new Error('Article not found');
            }

            helpers.formatArticle(article);
            state.all.splice(index, 1, article);
        },

        /**
         * Set current article.
         * @param {Object} state
         * @param {Object} article
         */
        setArticle(state, article)
        {
            state.cArticle = article;
        },
    },


    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        /**
         * Initialize this store module.
         * @see `initializationActions` in src/store/index.js.
         */
        initialize({ commit, getters })
        {
        },

        /**
         * Retrieve the list of articles and save it.
         * @returns {Promise}
         */
        fetchAll({ commit }, options = {})
        {
            return new Promise((resolve, reject) =>
            {
                let url = '/api/article';

                if(options.withTags)
                {
                    url += '/with-tags';
                }

                axios.get(url)
                    .then(response =>
                    {
                        commit('setAll', response.data);

                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        console.error(error);

                        reject(error);
                    });
            });
        },

        fetchArticle({ commit, getters }, articleId)
        {
            return new Promise((resolve, reject) =>
            {
                axios.get(`/api/article/${articleId}`)
                    .then(response =>
                    {
                        const article = response.data;
                        let mutation;

                        // Add to or update in articles list
                        if(getters.oneById(article.id))
                        {
                            mutation = 'updateInArticlesList';
                        }
                        else
                        {
                            mutation = 'addToArticlesList';
                        }

                        commit(mutation, article);

                        resolve(article);
                    })
                    .catch(error =>
                    {
                        console.error(error.message);

                        reject(error);
                    });
            });
        },

        addToArticlesList({ commit }, article)
        {
            commit('addToArticlesList', article);

            return Promise.resolve(article);
        },

        updateInArticlesList({ commit }, article)
        {
            commit('updateInArticlesList', article);

            return Promise.resolve(article);
        },

        setCurrentArticle({ commit }, article)
        {
            commit('setArticle', article);

            return Promise.resolve(article);
        },

        /**
         * Insert a new article via ajax and reflect it to the store.
         * @param {{}} article
         * @returns {Promise}
         */
        create({ commit }, article)
        {
            return axios.post('/api/article', article)
                .then(response =>
                {
                    // Save returned article in the store
                    commit('addToArticlesList', response.data);

                    return response;
                });
        },

        /**
         * Update an existing article via ajax and reflect it to the store.
         * @param {{}} article
         * @returns {Promise}
         */
        update({ commit }, article)
        {
            return axios.put(`/api/article/${article.id}`, article)
                .then(response =>
                {
                    // Save returned article in the store
                    commit('updateInArticlesList', response.data);

                    return response;
                });
        },
    },
};
