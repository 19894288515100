<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiMenu" />
            </div>

            <div class="cs-card__title">
                Extended Properties
            </div>

            <div class="flex-grow"></div>

            <div class="cs-card__icon-r" title="Edit Properties" @click="editProperties">
                <mdi-icon v-if="properties.length" icon="mdiPencil" />
                <mdi-icon v-else icon="mdiPlus" />
            </div>
        </template>

        <div>
            <table v-if="properties.length" class="cs-property-table">
                <tbody>
                    <tr
                        v-for="(property, index) in properties"
                        :key="property.pkey + '-' + index"
                        class="cs-property-table__line"
                        :class="index % 2 ? 'cs-property-table__line--odd' : 'cs-property-table__line--even'"
                    >
                        <th class="cs-property-table__label capitalize">
                            {{ property.pkey.replace(/_|\./g, ' ') }}
                        </th>

                        <td class="cs-property-table__value">
                            {{ property.formatted_value || property.pvalue }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-else class="cs-card__no-content">
                There are no extended properties for this article.
            </div>
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import CsCard from '@/components/CsCard.vue';

export default
{
    name: 'ArticlePropertiesCard',

    components:
    {
        CsCard,
    },

    props:
    {
        article:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        properties()
        {
            return (this.article.extra?.length ? [...this.article.extra] : [])
                .sort((a, b) => (a.pkey < b.pkey ? -1 : (a.pkey > b.pkey ? 1 : 0)));
        },
    },

    methods:
    {
        /**
         * Open the "Edit Properties" form.
         */
        editProperties()
        {
            this.$router.push({ name: 'edit-article-properties', params: { article_id: this.$route.params.article_id } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.cs-property-table__label
{
    width: 110px;
}
</style>
