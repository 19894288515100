export default Object.freeze(
    {
        type: Object.freeze(
            {
                ARTICLE:    'ARTICLE',
                SUPPLEMENT: 'SUPPLEMENT',
                PACK:       'PACK',
            }
        ),

        /**
         * The status of an article (or article price).
         */
        status: Object.freeze(
            {
                ACTIVE:       'ACTIVE',
                REORDER_ONLY: 'REORDER_ONLY',
                INACTIVE:     'INACTIVE',
            }
        ),

        /**
         * To whom an article price may be displayed, from the most restrictive to the most permissive.
         */
        visibility: Object.freeze(
            {
                DEVELOPER:      'DEVELOPER',
                INTERNAL_STAFF: 'INTERNAL_STAFF',
                EXTERNAL_STAFF: 'EXTERNAL_STAFF',
                ALL:            'ALL',
            }
        ),
    }
);
