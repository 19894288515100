<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div
        v-click-outside="close"
        class="cs-context-menu"
        :class="{ 'cs-context-menu--open': isOpen }"
        :style="positionPx"
        @click.left.stop
        @click.right.stop.prevent
        @dblclick.stop
    >
        <template v-if="cell">
            <!-- Edit Price -->
            <div class="cs-context-menu__item" :class="{ 'cs-context-menu__item--disabled': !canEdit }" @click.stop="editPrice">
                <div>Edit Price</div>
            </div>

            <!-- Close Price -->
            <div class="cs-context-menu__item" :class="{ 'cs-context-menu__item--disabled': !canEdit }" @click.stop="closePrice">
                <div>Close Price</div>
            </div>

            <!-- Close Price -->
            <div class="cs-context-menu__item hover:bg-red-600" :class="{ 'cs-context-menu__item--disabled': !canDelete }" @click.stop="deletePrice">
                <div>
                    Delete Price
                </div>
            </div>

            <!-- Permissions -->
            <div class="cs-context-menu__item cs-context-menu__item--not-clickable cs-context-menu__item--has-submenu" :class="{ 'cs-context-menu__item--disabled': !canEdit }">
                <div>Permissions</div>

                <div class="cs-context-menu cs-context-menu--submenu">
                    <div
                        v-for="perm in $store.state.articlePermissions.all"
                        :key="perm.id"
                        class="cs-context-menu__item"
                        :class="{
                            'cs-context-menu__item--checked': cell.articlePrice.permission & perm.bin,
                            'cs-context-menu__item--disabled': cell.isSaving
                        }"
                        @click="setPermission(perm.bin)"
                    >
                        {{ perm.label }}
                        <span v-if="cell.articlePrice.permission & perm.bin">
                            <mdi-icon icon="mdiCheck" class="w-4 h-4" />
                        </span>
                    </div>
                </div>
            </div>

            <!-- Status -->
            <div class="cs-context-menu__item cs-context-menu__item--not-clickable cs-context-menu__item--has-submenu" :class="{ 'cs-context-menu__item--disabled': !canEdit }">
                <div>Status</div>

                <div class="cs-context-menu cs-context-menu--submenu">
                    <div
                        v-for="(binStatus, statusText) in cell.allStatuses"
                        :key="binStatus"
                        class="cs-context-menu__item"
                        :class="{
                            'cs-context-menu__item--checked': cell.articlePrice.bin_status & binStatus,
                            'cs-context-menu__item--disabled': cell.isSaving
                        }"
                        @click="setStatus(binStatus)"
                    >
                        {{ formatStatusText(statusText) }}
                        <span v-if="cell.articlePrice.bin_status & binStatus">
                            <mdi-icon icon="mdiCheck" class="w-4 h-4" />
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
        <!--
            No cell selected?
            No context menu to display!
        -->
        </template>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import Strings from '@/utils/strings';

export default
{
    name: 'CsContextMenu',

    props:
    {
        cell:
        {
            type:    [Object, null],
            default: null,
        },

        isOpen:
        {
            type:    Boolean,
            default: false,
        },

        position:
        {
            type: Object,
            default()
            {
                return {
                    top:  0,
                    left: 0,
                };
            },
        },
    },

    emits: [
        'close',
    ],

    computed:
    {
        positionPx()
        {
            return {
                top:  (this.position.top  || 0) + "px",
                left: (this.position.left || 0) + "px",
            };
        },

        /**
         * A price is editable only when its end of validity is set.
         */
        canEdit()
        {
            if(!this.cell)
                return false;

            return !this.cell.articlePrice.valid_until;
        },

        canDelete()
        {
            if(!this.cell)
                return false;

            const today = new Date().toISOString().slice(0, 10);

            return this.cell.articlePrice.valid_from >= today; // cannot delete a price with valid_from in the past
        },
    },

    methods:
    {
        close()
        {
            this.$emit('close');
        },

        setVisibility(visibility)
        {
            if(!this.canEdit)
            {
                return;
            }

            // Only change visibility if selecting a different one
            if(this.cell.articlePrice.visibility === visibility)
            {
                this.close();
                return;
            }

            this.cell.savePriceVisibility(visibility);
            this.close();
        },

        setStatus(binStatus)
        {
            if(!this.canEdit)
            {
                return;
            }

            this.cell.savePriceStatus(binStatus);
        },

        setPermission(permNumber)
        {
            if(!this.canEdit)
            {
                return;
            }

            this.cell.savePricePermission(permNumber);
        },

        /**
         * Format a status text.
         *
         * @param {String} statusText
         * @returns {String} For example: `"STATUS_REORDER_ONLY__EXTERNAL_STAFF"` → `"Reorder Only: External Staff"`
         */
        formatStatusText(statusText)
        {
            return statusText
                .replace(/^STATUS_/, '')
                .split('__')
                .map(
                    part => part
                        .split('_')
                        .map(
                            word => Strings.capitalize(word)
                        )
                        .join(' ')
                )
                .join(': ');
        },

        editPrice()
        {
            if(!this.canEdit)
            {
                return;
            }

            this.cell.startEditing();
            this.close();
        },

        closePrice()
        {
            if(!this.canEdit)
            {
                return;
            }

            this.cell.closePrice();
            this.close();
        },

        deletePrice()
        {
            if(!this.canDelete)
            {
                return;
            }

            this.close();
            this.$nextTick(() =>
            {
                this.cell.deletePrice();
            });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
