<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="mdi-icon">
        <svg viewBox="0 0 24 24">
            <path fill="currentColor" :d="iconSvg" />
        </svg>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'MdiIcon',

    props:
    {
        icon:
        {
            type:     String,
            required: true,
        },
    },

    computed:
    {
        iconSvg()
        {
            if(this.icon.slice(0, 3) === 'mdi')
            {
                const svg = this.$const.icons[this.icon];
                if(!svg)
                {
                    throw new Error(`Unknown icon: "${this.icon}"`);
                }

                return svg;
            }

            return this.icon;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
