<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiTagOutline" />
            </div>

            <div class="cs-card__title">
                Tags
            </div>

            <div class="flex-grow"></div>

            <div class="cs-card__icon-r" title="Edit Tags" @click="editTags">
                <mdi-icon v-if="article.tags?.length" icon="mdiPencil" />
                <mdi-icon v-else icon="mdiPlus" />
            </div>
        </template>

        <div v-if="article.tags?.length" class="flex flex-wrap">
            <div
                v-for="tag in article.tags"
                :key="tag.id"
                class="border border-gray-300 rounded px-3 py-1 mr-1 mb-1 text-sm text-center"
            >
                {{ tag.label }}
            </div>
        </div>

        <div v-else class="cs-card__no-content">
            There are no tags for this article.
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import CsCard from '@/components/CsCard.vue';

export default
{
    name: 'ArticleTagsCard',

    components:
    {
        CsCard,
    },

    props:
    {
        article:
        {
            type:     Object,
            required: true,
        },
    },

    methods:
    {
        editTags()
        {
            this.$router.push(
                {
                    name: 'edit-article-tags',
                    params:
                    {
                        article_id: this.article.id,
                    },
                }
            );
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
