<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-main-area">
        <div class="page">
            <div class="page-header">
                <div class="page-header__title">
                    Tags Management
                </div>
            </div>

            <div class="page-toolbar">
                <div class="lsn-toolbar__search">
                    <input ref="searchInput" v-model="searchText" type="text" class="lsn-toolbar__search-field" />
                </div>

                <div class="lsn-btn lsn-btn-icon lsn-btn--primary-outline">
                    <mdi-icon class="hw-4" icon="mdiFilter" />
                </div>

                <div class="ml-4 lsn-btn lsn-btn--primary" @click="showNewTagForm">
                    New Tag
                </div>
            </div>

            <div class="page-content">
                <div class="w-1/4">
                    <tags-list v-if="!loading" :search-text="searchText" />

                    <div v-else>
                        <img src="/img/loader-761.svg" alt="Loading..." class="mx-auto mt-4">
                    </div>
                </div>

                <div class="w-3/4 p-4">
                    <tag-details v-if="$route.params.tag_id" />

                    <div v-else-if="!loading">
                        Select a tag in the list on the left to see its details here.
                    </div>
                </div>
            </div>
        </div>

        <!-- Side bar: Edition form -->
        <router-view v-slot="{ Component }" :key="siderKey" name="sider">
            <lsn-sider :is-open="$route.meta.isSiderOpen && !loading" class="w-1/3">
                <component :is="Component" />
            </lsn-sider>
        </router-view>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import TagsList   from '@/views/tags/TagsList.vue';
import TagDetails from '@/views/tags/TagDetails.vue';

export default
{
    name: 'Tags',

    components:
    {
        TagsList,
        TagDetails,
    },

    data()
    {
        return {
            loading:    true,
            searchText: '',
        };
    },

    computed:
    {
        siderKey()
        {
            return this.$route.fullPath + '/sider';
        },
    },

    created()
    {
        this.loading = true;

        // Retrieve the list of tags.
        this.$store.dispatch('tags/fetchAll')
            .then()  // nothing
            .catch() // nothing
            .then(() =>
            {
                this.loading = false;
            });
    },

    methods:
    {
        /**
         * Navigate to the "New Tag" form.
         */
        showNewTagForm()
        {
            this.$router.push({ name: 'new-tag' });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
