<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-article-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div>
                Edit Pack Contents
            </div>

            <div class="flex-grow"></div>

            <div v-if="loading">
                <img src="/img/loader-5.gif" alt="..." class="inline w-4 h-4">
            </div>
        </header>

        <!-- Form -->
        <div class="cs-sider__content p-4">
            <!-- Pack Contents -->
            <div
                v-for="(childArticle, index) in cArticle.pack_child_articles"
                :key="index"
            >

                <!-- <pre>{{ childArticle }}</pre> -->
            </div>

            <!-- Error message, if any -->
            <p v-if="errorMessage" class="lsn-form__error mb-4">
                {{ errorMessage }}
            </p>
        </div>

        <div class="cs-sider__actions">
            <div class="lsn-field-wrapper">
                <div class="lsn-btn lsn-btn--primary-outline" @click="close">
                    Close
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import axios from '@/axios';

export default
{
    name: 'ArticlePackContentsForm',

    data()
    {
        return {
            // Component-specific attributes
            loading:      false,
            errorMessage: '',
            errors:       {},
        };
    },

    computed:
    {
        /**
         * The currently selected article.
         */
        cArticle()
        {
            return this.$store.state.articles.cArticle;
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            // @todo
        },

        close()
        {
            const article_id = parseInt(this.$route.params.article_id);
            this.$router.push({ name: 'view-article', params: { article_id } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
