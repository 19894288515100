<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-main-area">

    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'Home',

    components:
    {

    },

    // created()
    // {
    //     this.$store.dispatch('articles/fetchAll');
    // },

    methods:
    {

    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

</style>
