import app           from './app';
import articles      from './articles';
import articlePrices from './articlePrices';
import icons         from './icons';

/**
 * The application's constants.
 */
export default Object.freeze(
    {
        app,
        articles,
        articlePrices,
        icons,
    }
);
