<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiFileExcelOutline" />
            </div>

            <div class="cs-card__title">
                Export Prices
            </div>
        </template>

        <div>
            <div>
                Download an Excel spreadsheet of all current prices.
            </div>

            <div class="mt-4">
                <lsn-input
                    v-model="date"
                    class="lsn-form__wrapper--padless mb-4"
                    label="Date"
                    ckey="date"
                    :placeholder="dateFormat"
                    :error="dateError"
                />
            </div>

            <div class="flex justify-end mt-8">
                <div
                    tabindex="0"
                    class="lsn-btn lsn-btn--primary-outline"
                    :class="{ 'lsn-btn--disabled': loading || !!dateError }"
                    @click="download"
                >
                    <template v-if="loading">
                        Getting Prices…
                    </template>

                    <template v-else>
                        Get Prices
                    </template>
                </div>
            </div>
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import {
    format,
    parse,
    isValid,
} from 'date-fns';
import axios  from '@/axios';
import CsCard from '@/components/CsCard.vue';

const DATE_FORMAT = 'yyyy-MM-dd';

export default
{
    name: 'ExportPricesCard',

    components:
    {
        CsCard,
    },

    data()
    {
        return {
            loading: false,
            date:    format(new Date(), DATE_FORMAT),
        };
    },

    computed:
    {
        btnLabel()
        {
            return this.loading ? 'Getting Prices…' : 'Get Prices';
        },

        dateError()
        {
            let error = null;

            try
            {
                const d = parse(this.date, DATE_FORMAT, new Date());
                if(!isValid(d))
                {
                    throw new Error(`"${this.date}" is invalid. Format: ${DATE_FORMAT}`);
                }
            }
            catch(e)
            {
                error = e.message;
            }

            return error;
        },

        dateFormat()
        {
            return DATE_FORMAT;
        },
    },

    methods:
    {
        download()
        {
            if(this.loading)
            {
                return;
            }

            if(this.dateError !== null)
            {
                return;
            }

            this.loading = true;

            axios.get(`/api/article-price/export/${this.date}`, { timeout: 15_000 })
                .then(reponse =>
                {
                    // Use an in-memory <a> tag to download the base64-encoded Excel file
                    const a = document.createElement('a');
                    a.href = `data:application/octet-stream;base64,${reponse.data}`;
                    a.target = '_blank';
                    a.download = `Prices Export ${this.date}.xlsx`;
                    a.click();
                })
                .catch(error =>
                {
                    // todo: handle error
                })
                .then(()=>
                {
                    this.loading = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
