<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiMenu" />
            </div>

            <div class="cs-card__title">
                Pack Contents
            </div>

            <div class="flex-grow"></div>

            <!-- todo: Uncomment this "Edit" button -->
            <!-- <div class="cs-card__icon-r" title="Edit Contents" @click="editContents">
                <mdi-icon v-if="contents.length" icon="mdiPencil" />
                <mdi-icon v-else icon="mdiPlus" />
            </div> -->
        </template>

        <div>
            <table v-if="contents" class="cs-property-table">
                <tbody>
                    <tr>
                        <th class="cs-property-table__label">
                            Contents
                        </th>
                    </tr>

                    <tr
                        v-for="(childArticle, index) in contents"
                        :key="index"
                        class="cs-property-table__line"
                        :class="index % 2 ? 'cs-property-table__line--odd' : 'cs-property-table__line--even'"
                    >
                        <td class="cs-property-table__value">
                            <div class="cursor-pointer hover:text-primary-500" @click="toggleDetails(index)">
                                {{ childArticle.label }}
                            </div>

                            <!-- <pre v-if="articleDataIsOpen[index]" class="my-2 text-xs">{{ childArticle.default_article_data }}</pre> -->

                            <table v-if="articleDataIsOpen[index]" class="cs-property-table mt-2 text-xs border border-gray-100">
                                <tbody>
                                    <tr
                                        v-for="(value, key, i) in childArticle.default_article_data"
                                        :key="key"
                                        class="cs-property-table__line"
                                        :class="i % 2 ? 'cs-property-table__line--odd' : 'cs-property-table__line--even'"
                                    >
                                        <th class="cs-property-table__label">
                                            {{ key }}
                                        </th>

                                        <td v-if="value === null" class="text-gray-400">
                                            &empty;
                                        </td>
                                        <td v-else>
                                            {{ value }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-else class="cs-card__no-content">
                This pack has no contents.
            </div>
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import CsCard from '@/components/CsCard.vue';

export default
{
    name: 'ArticlePackContentsCard',

    components:
    {
        CsCard,
    },

    props:
    {
        article:
        {
            type:     Object,
            required: true,
        },
    },

    data()
    {
        return {
            articleDataIsOpen: [],
        };
    },

    computed:
    {
        contents()
        {
            return this.article.pack_child_articles
                .slice()
                .sort((a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0)); // sort alphabetically
        },
    },

    created()
    {
        // Close all "default article data" sections
        this.articleDataIsOpen = new Array(this.contents.length).fill(false);
    },

    methods:
    {
        /**
         * Open the "Edit Pack Contents" form.
         */
        editContents()
        {
            this.$router.push({ name: 'edit-article-pack-contents', params: { article_id: this.$route.params.article_id } });
        },

        toggleDetails(index)
        {
            this.articleDataIsOpen[index] = !this.articleDataIsOpen[index];
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
