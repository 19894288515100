<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in" appear>
            <component :is="Component" @initialize="initialize()" />
        </transition>
    </router-view>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'Application',

    created()
    {
        if(this.$store.state.auth.authenticated)
        {
            this.initialize();
        }
    },

    methods:
    {
        initialize()
        {
            this.$store.dispatch('initialize').then(() =>
            {
                let query = this.$route.query;
                let params = null;
                let redirectRoute = null;

                // If a redirect route was given in URL.
                if(query.redirect)
                {
                    redirectRoute = this.$router.resolve(query.redirect);
                }

                // Default route after initialization (if no other is given - see below)
                let routeName = 'home';

                delete query.redirect; // Remove "redirect URL" parameter from URL query.

                // If a route was found (corresponding to redirection route) and this
                // route is not "initializing" (avoid redirction loop).
                if(redirectRoute && redirectRoute.name && redirectRoute.name !== 'initializing')
                {
                    routeName = redirectRoute.name;

                    if(redirectRoute.params)
                    {
                        params = redirectRoute.params;
                    }
                }

                // Navigate to final route.
                this.$router.replace({
                    name: routeName,
                    params,
                    query,
                });
            });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
