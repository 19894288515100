<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiHanger" />
            </div>

            <div class="cs-card__title">
                Article
            </div>

            <div class="flex-grow"></div>

            <div class="cs-card__icon-r" title="Edit Article" @click="editArticle">
                <mdi-icon icon="mdiPencil" />
            </div>
        </template>

        <div>
            <table class="cs-property-table">
                <tbody>
                    <tr
                        v-for="(value, key, index) in displayedProperties"
                        :key="index"
                        class="cs-property-table__line"
                        :class="index % 2 ? 'cs-property-table__line--odd' : 'cs-property-table__line--even'"
                    >
                        <th class="cs-property-table__label">
                            {{ key }}
                        </th>

                        <!-- eslint-disable vue/no-v-html -->
                        <td class="cs-property-table__value" v-html="value"></td>
                        <!-- eslint-enable vue/no-v-html -->
                    </tr>
                </tbody>
            </table>
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { clone } from 'lodash-es';
import Strings   from '@/utils/strings';
import CsCard    from '@/components/CsCard.vue';

export default
{
    name: 'ArticleCard',

    components:
    {
        CsCard,
    },

    props:
    {
        article:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        displayedProperties()
        {
            const article = clone(this.article);

            return {
                'ID':               article.id,
                'External ID':      article.ide_,
                'Type':             Strings.capitalize(article.type),
                'Status':           Strings.capitalize(article.status),
                'Photo ID':         article.photo_id,
                'Label':            article.label,
                'Code':             article.code,
                'Article Class':    article.article_class,
                'Order Class':      article.order_class,
                'Internal Comment': article.icom?.replace(/\n/g, '<br>') || '',
            };
        },
    },

    methods:
    {
        /**
         * Open the "Edit Article" form.
         */
        editArticle()
        {
            this.$router.push({ name: 'edit-article', params: { article_id: this.$route.params.article_id } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.cs-property-table__label
{
    width: 110px;
}
</style>
