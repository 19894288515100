<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-main-area">
        <div class="page max-h-full">
            <!-- Page Content -->
            <div v-if="!initialLoading" class="page-content">
                <!-- Change Dates -->
                <div class="cs-timeline" @scroll="timelineScrolled">
                    <div class="cs-timeline__btn-area cs-timeline__btn-area--left">
                        <!-- Scroll Timeline Left Button -->
                        <div class="cs-timeline__btn cs-timeline__btn--left" @click="scrollTimeline('left')">
                            <mdi-icon icon="mdiChevronLeft" class="w-6 h-6" />
                        </div>
                    </div>

                    <!-- Timeline Content (dates) -->
                    <div ref="timeline" class="cs-timeline__dates">
                        <div
                            v-for="date in changeDates"
                            :key="date"
                            class="cs-timeline__date"
                            :class="dateClass(date)"
                            :data-date="date"
                            @click="selectDate(date)"
                        >
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <div v-html="timeLineDate(date)"></div>
                        </div>
                    </div>

                    <div class="cs-timeline__btn-area cs-timeline__btn-area--right">
                        <!-- Date Picker Button -->
                        <div ref="datePickerButton" v-click-outside="closeDatePicker" class="cs-timeline__btn cs-timeline__btn--datepicker" @click="datePickerOpen = !datePickerOpen">
                            <mdi-icon icon="mdiCalendar" class="w-6 h-6" />

                            <div v-if="datePickerOpen" class="absolute top-full right-0 mt-2">
                                <v-date-picker v-model="pickedDate" class="cs-timeline__datepicker" color="purple" @dayclick="datepickerClicked" />
                            </div>
                        </div>

                        <!-- Scroll Timeline Right Button -->
                        <div class="cs-timeline__btn cs-timeline__btn--right" @click="scrollTimeline('right')">
                            <mdi-icon icon="mdiChevronRight" class="w-6 h-6" />
                        </div>
                    </div>
                </div>

                <!-- Price Table -->
                <div class="cs-price-table border-t border-gray-300">
                    <table class="cs-price-table__wrapper">
                        <!-- First Row: Names of the Price Groups -->
                        <tr class="cs-price-table__header cs-price-table__row">
                            <th class="cs-price-table__cell cs-price-table__label">
                                <div class="cs-price-table__title">
                                    <div>Articles</div>

                                    <mdi-icon icon="mdiFilter" @click="openFilters" />
                                </div>
                            </th>

                            <th
                                v-for="priceGroup in selectedPriceGroups"
                                :key="'h-' + priceGroup.id"
                                class="cs-price-table__cell cs-price-table__label"
                                :title="priceGroup.code"
                            >
                                {{ priceGroup.label }}
                                <br>
                                {{ priceGroup.currency_code }}
                            </th>
                        </tr>

                        <tr
                            v-for="(article, index) in articles"
                            :key="'row-' + article.id"
                            class="cs-price-table__row"
                            :class="getRowClasses(article, index)"
                        >
                            <th
                                class="cs-price-table__cell cs-price-table__label cs-price-table__article"
                                :class="article.status === $const.articles.status.INACTIVE"
                                @dblclick="viewArticle(article.id)"
                            >
                                {{ article.label }}
                            </th>

                            <price-table-data-cell
                                v-for="priceGroup in selectedPriceGroups"
                                :key="'c-' + priceGroup.id + '-' + article.id"
                                :price-group-id="priceGroup.id"
                                :article-id="article.id"
                                @start-editing="openEditors++"
                                @stop-editing="decrementOpenEditors"
                                @open-context-menu="openContextMenu"
                            />
                        </tr>
                    </table>
                </div>
            </div>

            <div v-else class="text-center mt-4">
                <img src="/img/loader-761.svg" alt="Loading..." class="inline">
            </div>
        </div>

        <!-- Side Bar -->
        <router-view v-slot="{ Component }" :key="$route.name" name="sider">
            <lsn-sider :is-open="$route.meta.isSiderOpen && !initialLoading" class="w-1/3">
                <component :is="Component" />
            </lsn-sider>
        </router-view>

        <!-- Prices Loader -->
        <teleport to="body">
            <div v-if="pricesLoading" id="prices-loader">
                <img src="/img/loader-761.svg" alt="Loading...">
            </div>
        </teleport>

        <!-- Context Menu -->
        <teleport to="body">
            <cs-context-menu
                :cell="ctxCell"
                :is-open="isContextMenuOpen"
                :position="contextMenuPosition"
                @close="closeContextMenu"
            />
        </teleport>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
// ------------------------------------------------------------ IMPORTS

import { clone,debounce }            from 'lodash-es';
import moment                        from 'moment';
import { DatePicker as VDatePicker } from 'v-calendar';
import PriceGroupsFilter             from './PriceGroupsFilter.vue';
import PriceTableDataCell            from '@/components/price-groups/PriceTableDataCell.vue';
import CsContextMenu                 from '@/components/CsContextMenu.vue';
import constants from '@/constants/constants';


// ------------------------------------------------------------ CONSTANTS

/**
 * @constant {String} TODAY Today's date in the format "YYYY-MM-DD".
 * @example 2021-11-30
 */
const TODAY = moment().format('YYYY-MM-DD');

/**
 * @constant {Number} SCROLL_AMOUNT The amount by which the timeline's scroll buttons scroll.
 * This should match the timeline date buttons’ `width + margins`.
 * These dimensions are defined in `custom.css`, at the `.cs-timeline__date` rule.
 */
const SCROLL_AMOUNT = 72; // width: 60px + margin: 12px


// ------------------------------------------------------------ NAVIGATION GUARDS

const navigationGuard = function(to, from, next)
{
    // Bugfix: don't edit the route object directly
    const finalDestination = {
        name: to.name,
        params:
        {
            date:         to.params.date,
            price_groups: to.params.price_groups,
        },
    };

    // Redirect with next({…}) only if we changed something
    let redirect = false;

    // If no date is selected, default to today
    if(!to.params.date)
    {
        finalDestination.params.date = TODAY;
        redirect = true;
    }

    // If no price group is selected, redirect to the selection view
    if(to.name === 'price-groups' && !to.params.price_groups)
    {
        finalDestination.name = 'select-price-groups';
        redirect = true;
    }

    // Continue navigation
    if(redirect)
    {
        next(finalDestination);
    }
    else
    {
        next();
    }
};


// ------------------------------------------------------------ COMPONENT

export default
{
    name: 'PriceGroups',

    components:
    {
        VDatePicker,
        PriceGroupsFilter,
        PriceTableDataCell,
        CsContextMenu,
    },

    beforeRouteEnter:  navigationGuard,
    beforeRouteUpdate: navigationGuard,

    data()
    {
        const date = this.$store.getters['priceGroups/selectedDate'];

        return {
            initialLoading:      true,
            pricesLoading:       false,
            datePickerOpen:      false,
            selectedCells:       [],
            openEditors:         0,              // Keep track of how many editors are currently open
            pickedDate:          new Date(date), // Date selected in the datepicker modal
            ctxCell:             null,
            isContextMenuOpen:   false,
            contextMenuPosition: {},
        };
    },

    computed:
    {
        siderKey()
        {
            return this.$route.fullPath + '/sider';
        },

        articles()
        {
            const articles = this.$store.state.articles.all;
            const selectedTags = this.$store.state.tags.selectedTags;

            // If no tag is selected, return all articles
            if(!selectedTags.length)
            {
                return articles;
            }

            // Only return articles which have at least one selected tag
            return articles.filter(a =>
            {
                const tagIds = a.tags.map(t => t.id);
                for(const tagId of tagIds)
                {
                    if(selectedTags.findIndex(t => t.id === tagId) !== -1)
                    {
                        return true;
                    }
                }

                return false;
            });
        },

        selectedPriceGroups()
        {
            return this.$store.getters['priceGroups/selected'];
        },

        changeDates()
        {
            const changeDates = clone(this.$store.state.priceGroups.changeDates);
            const selectedDate = clone(this.$store.getters['priceGroups/selectedDate']);

            // Ensure today is part of the timeline
            if(!changeDates.includes(TODAY))
            {
                changeDates.push(TODAY);
            }

            // Ensure the selected date is part of the timeline (e.g. if we're viewing a future date)
            if(!changeDates.includes(selectedDate))
            {
                changeDates.push(selectedDate);
            }

            // Sort the dates again
            changeDates.sort();

            return changeDates;
        },
    },

    watch:
    {
        /**
         * Watch selected price groups to load/reload the corresponding prices.
         */
        '$route.params.price_groups'()
        {
            this.fetchPrices();
        },

        /**
         * Watch selected date to load/reload the corresponding prices.
         */
        '$route.params.date'(date)
        {
            this.fetchPrices();
        },

        /**
         * Watch filtered articles to load/reload the corresponding prices.
         */
        'articles'(selectedTags)
        {
            this.fetchPrices();
        },
    },

    created()
    {
        // Debounced methods
        this.fetchPrices = debounce(this.fetchPrices, 500);

        // Component initialization
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            // Load articles, price groups and prices
            this.initialLoading = true;

            Promise.all([
                this.$store.dispatch('articles/fetchAll', { withTags: true }),
                this.$store.dispatch('priceGroups/fetchAll'),
                this.$store.dispatch('tags/fetchAll'),
                this.$store.dispatch('articlePermissions/fetchAll'),
            ])
                .then(([articles, priceGroups, tags, permissions]) =>
                {
                    // Load prices for the selected price groups for display
                    this.fetchPrices();

                    // Scroll to the currently selected date in the timeline
                    this.$store.dispatch('priceGroups/fetchChangeDates')
                        .then(changeDates =>
                        {
                            this.scrollToDate(this.$store.getters['priceGroups/selectedDate']);
                        });
                })
                .catch(error =>
                {
                    // TODO: Display error message
                    console.log('Error fetching initial data for PriceGroups.vue', error.response?.errors || error.message || error);
                })
                .then(() =>
                {
                    this.initialLoading = false;
                });
        },

        /**
         * Retrieve prices for the currently selected price groups.
         * This method is debounced in the `created` hook.
         */
        fetchPrices()
        {
            // Display loader (in DOM)
            this.pricesLoading = true;

            this.$store.dispatch('priceGroups/fetchArticlePrices', this.$store.getters['priceGroups/selectedDate'])
                .then(prices =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // TODO: Display error message
                    console.log('fetchPrices() ERROR', error);
                })
                .then(() =>
                {
                    // Hide loader (in DOM)
                    this.pricesLoading = false;
                });
        },

        /**
         * Open the filters side bar.
         */
        openFilters()
        {
            this.$router.push(
                {
                    name:   'select-price-groups',
                    params: this.$route.params,
                }
            );
        },

        decrementOpenEditors()
        {
            // Ensure openEditors is non-negative
            if(this.openEditors - 1 < 0)
            {
                return;
            }

            this.openEditors--;
        },

        viewArticle(article_id)
        {
            // Ensure we're not currently editing a value before navigating away
            if(this.openEditors > 0)
            {
                return false;
            }

            // Go the article
            this.$router.push(
                {
                    name: 'view-article',
                    params:
                    {
                        article_id,
                    },
                }
            );
        },

        timeLineDate(date)
        {
            const d = moment(date);
            return d.format('D MMM') + '<br>' + d.format('YYYY');
        },

        selectDate(targetDate)
        {
            if(targetDate instanceof Date)
            {
                // Accept `Date` instances, but convert them to strings
                targetDate = moment(targetDate).format('YYYY-MM-DD');
            }

            // Update the datepicker
            this.pickedDate = new Date(targetDate);

            // Navigate to the same page as now, but with the selected date param
            const destination = {
                name:   clone(this.$route.name),
                params: clone(this.$route.params),
            };
            destination.params.date = targetDate;

            this.$router.push(destination);
        },

        dateClass(date)
        {
            const classes = {
                'cs-timeline__date--selected': date === this.$route.params.date,
                'cs-timeline__date--today':    date === TODAY,
            };

            return classes;
        },

        scrollToDate(date)
        {
            const timeline = this.$refs.timeline;
            if(!timeline)
                return;

            // Find the button for the selected date
            for(const tlDate of timeline.childNodes)
            {
                if(tlDate.dataset?.date === date) // compare date with [date] attribute on element
                {
                    timeline.scrollTo(tlDate.offsetLeft - tlDate.offsetWidth, 0);
                    return;
                }
            }
        },

        scrollTimeline(direction)
        {
            const dir = (direction === 'left') ? -1 : 1;

            const cScroll = this.$refs.timeline.scrollLeft;
            this.$refs.timeline.scrollTo(cScroll + (SCROLL_AMOUNT * dir), 0);
        },

        datepickerClicked()
        {
            this.selectDate(this.pickedDate);
            this.closeDatePicker();
        },

        closeDatePicker()
        {
            this.datePickerOpen = false;
        },

        openContextMenu({ cell, event })
        {
            // Position the context menu
            this.contextMenuPosition = {
                top:  event.clientY,
                left: event.clientX,
            };

            // And open it
            this.ctxCell = cell;
            this.isContextMenuOpen = true;
        },

        closeContextMenu()
        {
            this.isContextMenuOpen = false;
        },

        getRowClasses(article, index)
        {
            const classes = [];

            if(index % 2)
            {
                classes.push('cs-price-table__row--odd');
            }
            else
            {
                classes.push('cs-price-table__row--even');
            }

            if(article.status === constants.articles.status.INACTIVE)
            {
                classes.push('cs-price-table__row--inactive');
            }

            return classes;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.page-content
{
    @apply flex-col;
}

#prices-loader
{
    @apply fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-60 backdrop-filter backdrop-blur-sm z-40;
}
</style>
