<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div v-if="!loading" id="article-details">
        <!-- Article Card (summary) -->
        <article-card :article="article" class="article-details__card" />

        <!-- Tags Card -->
        <article-tags-card :article="article" class="article-details__card" />

        <!-- Supplements Card -->
        <article-supplements-card
            v-if="article.type !== $const.articles.type.SUPPLEMENT"
            :article="article"
            class="article-details__card"
        />

        <!-- Article Properties Card -->
        <article-properties-card :article="article" class="article-details__card" />

        <!-- Article Pack Contents Card -->
        <article-pack-contents-card
            v-if="article.type === $const.articles.type.PACK"
            :article="article"
            class="article-details__card"
        />

        <div class="clear-both"></div>
    </div>

    <div v-else class="text-center">
        <img src="/img/loader-761.svg" alt="Loading..." class="inline">
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ArticleCard             from '@/components/articles/ArticleCard.vue';
import ArticleTagsCard         from '@/components/articles/ArticleTagsCard.vue';
import ArticleSupplementsCard  from '@/components/articles/ArticleSupplementsCard.vue';
import ArticlePropertiesCard   from '@/components/articles/ArticlePropertiesCard.vue';
import ArticlePackContentsCard from '@/components/articles/ArticlePackContentsCard.vue';

export default
{
    name: 'ArticleDetails',

    components:
    {
        ArticleCard,
        ArticleTagsCard,
        ArticleSupplementsCard,
        ArticlePropertiesCard,
        ArticlePackContentsCard,
    },

    emits:
    [
        'loading',
        'loaded',
    ],

    data()
    {
        return {
            loading: true,
        };
    },

    computed:
    {
        article()
        {
            return this.$store.state.articles.cArticle;
        },
    },

    watch:
    {
        '$route.params.article_id'(articleId)
        {
            if(parseInt(articleId))
            {
                this.initialize();
            }
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            // Load the article details
            this.loading = true;
            this.$emit('loading');

            this.$store.dispatch('articles/fetchArticle', this.$route.params.article_id)
                .then(article =>
                {
                    // Select the article for display
                    this.$store.dispatch('articles/setCurrentArticle', article);
                })
                .catch(error =>
                {
                    // nothing
                })
                .then(() =>
                {
                    this.loading = false;
                    this.$emit('loaded');
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
#article-details
{
    @apply columns-1 lg:columns-2 3xl:columns-3;

    .cs-card
    {
        // @apply float-left pr-4 pb-4 w-full lg:w-1/2 3xl:w-1/3;
        @apply mb-4;

        .no-content
        {
            @apply text-gray-400 italic;
        }
    }
}
</style>
