<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-price-group-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div>
                Filters
            </div>
        </header>

        <!-- Tabs -->
        <div class="cs-sider__tabs">
            <div class="cs-sider__tab-header" :class="{ 'cs-sider__tab-header--active': currentTab === 'price-groups' }" @click="currentTab = 'price-groups'">
                <div>Price Groups</div>
            </div>

            <div class="cs-sider__tab-header" :class="{ 'cs-sider__tab-header--active': currentTab === 'articles' }" @click="currentTab = 'articles'">
                <div>Articles</div>
            </div>
        </div>

        <!-- Price Groups Tab -->
        <div v-if="currentTab === 'price-groups'" class="cs-sider__content p-4">
            <!-- Filter by Currency -->
            <div class="mb-4">
                <div class="mb-2 text-lg text-gray-600">
                    Filter by Currency
                </div>

                <div class="flex items-center w-full flex-wrap">
                    <cs-checkbox
                        v-for="currency in currencies"
                        :key="currency"
                        class="mb-2 mr-3 cs-input--inline"
                        :label="currency"
                        name="currencies[]"
                        :value="currency"
                        @check="toggleCurrency(currency)"
                    />
                </div>
            </div>

            <!-- Price Groups List -->
            <div>
                <div class="mb-2 text-lg text-gray-600">
                    Select Price Groups
                </div>

                <cs-checkbox
                    v-for="pg in priceGroups"
                    :key="pg.id"
                    class="mb-2"
                    :label="getLabel(pg)"
                    name="price_groups[]"
                    :value="pg.id"
                    :is-checked="isPriceGroupSelected(pg.id)"
                    :is-disabled="!pg.is_active"
                    :title="pg.code"
                    @check="togglePriceGroup"
                />
            </div>
        </div>

        <!-- Articles Tab -->
        <div v-if="currentTab === 'articles'" class="cs-sider__content p-4">
            <!-- Filter by Tag -->
            <div class="mb-4">
                <div class="flex items-center mb-2 text-lg text-gray-600">
                    <span class="mr-2">
                        Filter by Tag
                    </span>

                    <!-- Deselect All Link -->
                    <span v-if="$store.state.tags.selectedTags.length" class="text-xs text-primary-500 cursor-pointer" @click="deselectAllTags">
                        Deselect All
                    </span>
                </div>

                <div class="flex items-center w-full flex-wrap">
                    <cs-checkbox
                        v-for="tag in tags"
                        :key="tag"
                        class="mb-2"
                        :label="tag.label"
                        :title="tag.code"
                        name="tags[]"
                        :value="tag.id"
                        :is-checked="isTagSelected(tag.id)"
                        @check="toggleTag($event, tag)"
                    />
                </div>
            </div>
        </div>

        <div class="cs-sider__actions">
            <div class="lsn-field-wrapper ml-3">
                <div class="lsn-btn" :class="closeClass" :title="closeTitle" @click="close">
                    Close
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { remove } from 'lodash-es';
import CsCheckbox from '@/components/CsCheckbox.vue';

export default
{
    name: 'PriceGroupsFilter',

    components:
    {
        CsCheckbox,
    },

    data()
    {
        return {
            currentTab:         'price-groups',
            selectedCurrencies: [],
        };
    },

    computed:
    {
        priceGroups()
        {
            const priceGroups = this.$store.state.priceGroups.all;

            // If there is no currency filter, return all price groups
            if(!this.selectedCurrencies.length)
            {
                return priceGroups;
            }

            return priceGroups.filter(pg => this.selectedCurrencies.includes(pg.currency_code));
        },

        /**
         * The IDs of the price groups currently selected for display.
         * @returns {Array}
         */
        selectedIds()
        {
            return this.$store.getters['priceGroups/selectedIds'];
        },

        /**
         * The list of price groups currently selected for display.
         * @returns {Array}
         */
        selectedPriceGroups()
        {
            return this.$store.getters['priceGroups/selected'];
        },

        closeClass()
        {
            if(this.selectedIds.length)
            {
                return ['lsn-btn--primary-outline'];
            }

            return ['lsn-btn--disabled'];
        },

        closeTitle()
        {
            if(this.selectedIds.length)
            {
                return '';
            }

            return 'Please select a price group first.';
        },

        currencies()
        {
            return this.$store.getters['priceGroups/currencies'];
        },

        tags()
        {
            return this.$store.state.tags.all;
        },
    },

    methods:
    {
        close()
        {
            if(this.selectedIds.length)
            {
                this.$router.push(
                    {
                        name:   'price-groups',
                        params: this.$route.params,
                    }
                );
            }
        },

        togglePriceGroup({ component, checked, value: priceGroupId })
        {
            const selected = this.selectedIds;

            // Update the route to match the selection
            if(checked)
            {
                selected.push(priceGroupId);
            }
            else
            {
                remove(selected, id => id === priceGroupId);
            }

            // Update the URL
            this.$router.replace(
                {
                    name: this.$route.name,
                    params:
                    {
                        price_groups: selected.join('-'),
                        date:         this.$route.params.date,
                    },
                }
            );
        },

        /**
         * Determine if a price group is selected, based on
         * whether its ID is present in the route params.
         * @returns {Boolean}
         */
        isPriceGroupSelected(priceGroupId)
        {
            return this.selectedIds.findIndex(id => id == priceGroupId) !== -1;
        },

        /**
         * Determine if a tag is selected, based on the store.
         * @returns {Boolean}
         */
        isTagSelected(tagId)
        {
            return this.$store.state.tags.selectedTags.findIndex(t => t.id == tagId) !== -1;
        },

        /**
         * Get the label for a given price group.
         * @returns {String}
         */
        getLabel(priceGroup)
        {
            let out = priceGroup.label;

            // If the label doesn't already contain the currency, append it between square brackets
            if(!out.includes(priceGroup.currency_code))
            {
                out += ` [${priceGroup.currency_code}]`;
            }

            return out;
        },

        toggleCurrency(currency)
        {
            const index = this.selectedCurrencies.indexOf(currency);
            if(index === -1)
            {
                // Add the currency to the filters
                this.selectedCurrencies.push(currency);
            }
            else
            {
                // Remove the currency from the filters
                this.selectedCurrencies.splice(index, 1);
            }
        },

        toggleTag({ component, checked }, tag)
        {
            this.$store.dispatch('tags/toggleSelectedTag', { tag, checked })
                .then(() =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    console.log('toggleTag ERROR', error);
                    component.checked = !checked;
                });
        },

        deselectAllTags()
        {
            this.$store.dispatch('tags/deselectAll')
                .then(() =>
                {
                    // Force re-rendering the tags list by rebuilding the store's "tags.all" array
                    const tagsCopy = JSON.parse(JSON.stringify(this.$store.state.tags.all));
                    this.$store.dispatch('tags/setAll', tagsCopy);
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
