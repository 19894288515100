<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-tag-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div v-if="tag.id">
                Edit Tag
            </div>

            <div v-else>
                New Tag
            </div>

            <div class="flex-grow"></div>

            <div v-if="loading">
                <img src="/img/loader-5.gif" alt="..." class="inline w-4 h-4">
            </div>

            <div v-else-if="isDirty" class="cursor-default text-4xl" title="Unsaved changes">
                &bull;
            </div>
        </header>

        <!-- Form -->
        <div class="cs-sider__content p-4">
            <lsn-input
                v-model="tag.label"
                class="lsn-form__wrapper--padless mb-4"
                label="Label"
                ckey="label"
                :error="errors.label"
            />

            <lsn-input
                v-model="tag.code"
                class="lsn-form__wrapper--padless mb-4"
                label="Code"
                ckey="code"
                :error="errors.code"
                :is-locked="!!tag.id"
                lock-text="The code cannot be updated because it is used to make a link with other applications."
            />

            <lsn-textarea
                v-model="tag.description"
                class="lsn-form__wrapper--padless mb-4"
                label="Description"
                ckey="description"
                :error="errors.description"
            />

            <p v-if="errorMessage" class="lsn-form__error mb-4">
                {{ errorMessage }}
            </p>

        </div>

        <div class="cs-sider__actions">
            <div class="lsn-field-wrapper">
                <div class="lsn-btn lsn-btn--primary-outline" @click="cancel">
                    Cancel
                </div>
            </div>

            <div class="lsn-field-wrapper ml-3">
                <div class="lsn-btn" :class="isDirty && !loading ? 'lsn-btn--primary' : 'lsn-btn--disabled'" @click="save">
                    Save
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { clone, isEqualWith } from 'lodash-es';

const defaultTag = {
    id:          0,
    label:       '',
    code:        '',
    description: '',
};

export default
{
    name: 'TagForm',

    data()
    {
        return {
            tag:          null,
            loading:      false,
            errorMessage: '',
            errors:       {},
        };
    },

    computed:
    {
        refTag()
        {
            const tagId = parseInt(this.$route.params.tag_id);

            return this.$store.getters['tags/oneById'](tagId);
        },

        types()
        {
            return [
                {
                    label: 'Tag',
                    code:  this.$const.tags.type.ARTICLE,
                },
                {
                    label: 'Supplement',
                    code:  this.$const.tags.type.SUPPLEMENT,
                },
                {
                    label: 'Pack',
                    code:  this.$const.tags.type.PACK,
                },
            ];
        },

        statuses()
        {
            return [
                {
                    label: 'Active',
                    code:  this.$const.tags.status.ACTIVE,
                },
                {
                    label: 'Reorder only',
                    code:  this.$const.tags.status.REORDER_ONLY,
                },
                {
                    label: 'Inactive',
                    code:  this.$const.tags.status.INACTIVE,
                },
            ];
        },

        isDirty()
        {
            if(this.tag.id)
            {
                return !isEqualWith(this.tag, this.refTag, this.nullStringComparator);
            }
            else
            {
                return !isEqualWith(this.tag, defaultTag, this.nullStringComparator);
            }
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            if(this.$route.params.tag_id)
            {
                this.tag = clone(this.refTag);
            }
            else
            {
                this.tag = clone(defaultTag);
            }
        },

        cancel()
        {
            const tag_id = parseInt(this.$route.params.tag_id);

            if(tag_id)
            {
                // Cancel editing an existing tag -> back to viewing that tag
                this.$router.push({ name: 'view-tag', params: { tag_id } });
            }
            else
            {
                // Cancel creating a new tag -> back to the tags list
                this.$router.push({ name: 'tags' });
            }
        },

        save()
        {
            if(!this.isDirty || this.loading)
            {
                return;
            }

            this.loading = true;

            const action = this.tag.id ? 'tags/update' : 'tags/create';
            this.$store.dispatch(action, this.tag)
                .then(tag =>
                {
                    this.errorMessage = '';
                    this.errors = {};

                    // Select the current tag in the store
                    this.$store.dispatch('tags/setCurrentTag', tag);

                    // Redirect to view the created/updated tag
                    this.$router.push({ name: 'view-tag', params: { tag_id: tag.id } });
                })
                .catch(error =>
                {
                    if(import.meta.env.MODE !== 'production')
                    {
                        console.log('Tag Form Error', error.toJSON?.() || error);
                        console.log('Tag Form Error Response', error.response);
                    }

                    // Set general error message
                    if(error.response?.message)
                    {
                        this.errorMessage = error.response.message;
                    }
                    else if(error.message)
                    {
                        this.errorMessage = error.message;
                    }
                    else if(typeof error === 'string')
                    {
                        this.errorMessage = error;
                    }

                    // Set field-specific errors
                    if(error.response?.errors)
                    {
                        this.errors = error.response.errors;
                    }
                })
                .then(() =>
                {
                    this.loading = false;
                });
        },

        /**
         * Custom comparator for use with Lodash's `isEqualWith()`.
         * Returns true if either operand is null and either operand is an empty string.
         * Returns undefined otherwise, to let the default comparator take place.
         *
         * @param {String|null} value1
         * @param {String|null} value2
         * @returns {true|undefined}
         * @see https://lodash.com/docs/4.17.15#isEqualWith
         */
        nullStringComparator(value1, value2)
        {
            if(value1 === null && value2 === '' ||
                value1 === '' && value2 === null)
            {
                return true;
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
