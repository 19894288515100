<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-input cs-input--checkbox" :class="cssClass">
        <label :for="fieldId" class="cs-input__label" :title="title" @click="toggleChecked">
            {{ label }}
        </label>

        <div class="cs-input__field" @click="toggleChecked">
            <mdi-icon
                :icon="checked ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                class="w-5 h-5"
            />

            <input type="hidden" :name="name" :value="checked ? value : ''" class="cs-input__input">
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import Hashids from 'hashids';

const hashids = new Hashids('CsCheckbox');
let nextId = Math.ceil(Math.random() * 99999); // arbitrary first field id

export default
{
    name: 'CsCheckbox',

    props:
    {
        label:
        {
            type:     String,
            required: true,
        },

        name:
        {
            type:     String,
            required: true,
        },

        value:
        {
            type:     [String, Number],
            required: true,
        },

        isChecked:
        {
            type:    Boolean,
            default: false,
        },

        isDisabled:
        {
            type:    Boolean,
            default: false,
        },

        title:
        {
            type:    String,
            default: '',
        },
    },

    emits:
    [
        'check',
    ],

    data()
    {
        return {
            checked:  false,
            disabled: false,
        };
    },

    computed:
    {
        fieldId()
        {
            const id = hashids.encode(nextId);

            return `cs-input_${id}`;
        },

        cssClass()
        {
            return {
                'cs-input--checked':  this.checked,
                'cs-input--disabled': this.disabled,
            };
        },
    },

    created()
    {
        this.id = nextId++;
        this.checked = this.isChecked;
        this.disabled = this.isDisabled;
    },

    methods:
    {
        toggleChecked()
        {
            if(this.disabled)
            {
                return;
            }

            this.checked = !this.checked;
            this.$emit('check',
                {
                    component: this,
                    checked:   this.checked,
                    value:     this.value,
                }
            );
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
