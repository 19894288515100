export default Object.freeze(
    {
        /**
         * The status of an article price.
         */
        binStatus: Object.freeze(
            {
                STATUS_ACTIVE__ALL:                  2 ** 0,  // 1
                STATUS_ACTIVE__EXTERNAL_STAFF:       2 ** 1,  // 2
                STATUS_ACTIVE__INTERNAL_STAFF:       2 ** 2,  // 4
                STATUS_ACTIVE__DEVELOPER:            2 ** 3,  // 8
                STATUS_REORDER_ONLY__ALL:            2 ** 4,  // 16
                STATUS_REORDER_ONLY__EXTERNAL_STAFF: 2 ** 5,  // 32
                STATUS_REORDER_ONLY__INTERNAL_STAFF: 2 ** 6,  // 64
                STATUS_REORDER_ONLY__DEVELOPER:      2 ** 7,  // 128
                STATUS_INACTIVE__ALL:                2 ** 8,  // 256
                STATUS_INACTIVE__EXTERNAL_STAFF:     2 ** 9,  // 512
                STATUS_INACTIVE__INTERNAL_STAFF:     2 ** 10, // 1_024
                STATUS_INACTIVE__DEVELOPER:          2 ** 11, // 2_048
            }
        ),
    }
);
