<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <cs-card>
        <template #header>
            <div class="cs-card__icon">
                <mdi-icon icon="mdiPackageVariantClosedPlus" />
            </div>

            <div class="cs-card__title">
                Possible Supplements
            </div>

            <div class="flex-grow"></div>

            <div class="cs-card__icon-r" title="Edit Supplements" @click="editSupplements">
                <mdi-icon v-if="article.supplements?.length" icon="mdiPencil" />
                <mdi-icon v-else icon="mdiPlus" />
            </div>
        </template>

        <div v-if="article.supplements?.length">
            <ul class="list-disc pl-5 space-y-1">
                <li
                    v-for="supplement in article.supplements"
                    :key="supplement.id"
                >
                    {{ supplement.label }}
                </li>
            </ul>
        </div>

        <div v-else class="cs-card__no-content">
            There are no possible supplements for this article.
        </div>
    </cs-card>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import CsCard from '@/components/CsCard.vue';

export default
{
    name: 'ArticleSupplementsCard',

    components:
    {
        CsCard,
    },

    props:
    {
        article:
        {
            type:     Object,
            required: true,
        },
    },

    methods:
    {
        editSupplements()
        {
            this.$router.push(
                {
                    name: 'edit-article-supplements',
                    params:
                    {
                        article_id: this.article.id,
                    },
                }
            );
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
