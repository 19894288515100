import constants from '@/constants/constants';

export default class ArticlePriceService
{
    static getStatus(articlePriceBinStatus, userVisibility = null)
    {
        if(articlePriceBinStatus === 0)
        {
            return constants.articles.status.INACTIVE;
        }

        userVisibility = userVisibility || constants.articles.visibility.ALL; // default visibility

        switch(userVisibility)
        {
            case constants.articles.visibility.DEVELOPER:
                return ArticlePriceService.getStatusForDeveloper(articlePriceBinStatus);

            case constants.articles.visibility.INTERNAL_STAFF:
                return ArticlePriceService.getStatusForInternalStaff(articlePriceBinStatus);

            case constants.articles.visibility.EXTERNAL_STAFF:
                return ArticlePriceService.getStatusForExternalStaff(articlePriceBinStatus);

            case constants.articles.visibility.ALL:
                return ArticlePriceService.getStatusForAll(articlePriceBinStatus);

            default:
                throw new Error(`Invalid user visibility: "${userVisibility}"`);
        }
    }

    static getStatusForDeveloper(status)
    {
        if((status & constants.articlePrices.binStatus.STATUS_ACTIVE__DEVELOPER) > 0)
        {
            return constants.articles.status.ACTIVE;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_REORDER_ONLY__DEVELOPER) > 0)
        {
            return constants.articles.status.REORDER_ONLY;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_INACTIVE__DEVELOPER) > 0)
        {
            return constants.articles.status.INACTIVE;
        }
        else
        {
            return ArticlePriceService.getStatusForInternalStaff(status);
        }
    }

    static getStatusForInternalStaff(status)
    {
        if((status & constants.articlePrices.binStatus.STATUS_ACTIVE__INTERNAL_STAFF) > 0)
        {
            return constants.articles.status.ACTIVE;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_REORDER_ONLY__INTERNAL_STAFF) > 0)
        {
            return constants.articles.status.REORDER_ONLY;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_INACTIVE__INTERNAL_STAFF) > 0)
        {
            return constants.articles.status.INACTIVE;
        }
        else
        {
            return ArticlePriceService.getStatusForExternalStaff(status);
        }
    }

    static getStatusForExternalStaff(status)
    {
        if((status & constants.articlePrices.binStatus.STATUS_ACTIVE__EXTERNAL_STAFF) > 0)
        {
            return constants.articles.status.ACTIVE;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_REORDER_ONLY__EXTERNAL_STAFF) > 0)
        {
            return constants.articles.status.REORDER_ONLY;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_INACTIVE__EXTERNAL_STAFF) > 0)
        {
            return constants.articles.status.INACTIVE;
        }
        else
        {
            return ArticlePriceService.getStatusForAll(status);
        }
    }

    static getStatusForAll(status)
    {
        if((status & constants.articlePrices.binStatus.STATUS_ACTIVE__ALL) > 0)
        {
            return constants.articles.status.ACTIVE;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_REORDER_ONLY__ALL) > 0)
        {
            return constants.articles.status.REORDER_ONLY;
        }
        else if((status & constants.articlePrices.binStatus.STATUS_INACTIVE__ALL) > 0)
        {
            return constants.articles.status.INACTIVE;
        }
        else
        {
            // Default to inactive
            return constants.articles.status.INACTIVE;
        }
    }

    static statusBinToText(binStatus)
    {
        const statuses = {};
        for(const [statusText, statusBin] of Object.entries(constants.articlePrices.binStatus))
        {
            if((binStatus & statusBin) > 0)
            {
                statuses[statusBin] = statusText.replace('STATUS_', '');
            }
        }

        return statuses;
    }

    static statusTextToBin(...textStatuses)
    {
        const allStatuses = constants.articlePrices.binStatus;
        let binStatus = 0;
        for(const textStatus of textStatuses)
        {
            const statusKey = `STATUS_${textStatus}`;
            if(!allStatuses[statusKey])
            {
                throw new Error(`Invalid status text: "${statusKey}"`);
            }
            binStatus |= allStatuses[statusKey];
        }

        return binStatus;
    }

    static getBinStatusDebugInfo(binStatus)
    {
        const statusTexts = this.statusBinToText(binStatus);
        const visibilities = {};
        for(const visibility of Object.values(constants.articles.visibility))
        {
            visibilities[visibility] = ArticlePriceService.getStatus(binStatus, visibility);
        }

        return {
            binStatus,
            statusTexts,
            visibilities,
        };
    }
}
