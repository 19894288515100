<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="app-layout__wrapper flex">

        <!-- Navbar -->
        <div class="lsn-side-primary-menu bg-black">

            <!-- App Switch -->
            <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right bg-black">
                <mdi-icon icon="mdiApps" class="hw-7" />
                <div class="lsn-tooltip__content bg-black py-2 px-3">
                    Applications
                </div>
            </div>

            <!-- Home -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'home' }" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{ 'lsn-side-primary-menu__item--active': isActive }" @click="navigate">
                    <mdi-icon icon="mdiHome" class="hw-7" />
                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Home
                    </div>
                </div>
            </router-link>

            <!-- Articles -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'articles' }" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{ 'lsn-side-primary-menu__item--active': isActive }" @click="navigate">
                    <mdi-icon icon="mdiHanger" class="hw-7" />
                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Articles
                    </div>
                </div>
            </router-link>

            <!-- Tags -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'tags' }" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{ 'lsn-side-primary-menu__item--active': isActive }" @click="navigate">
                    <mdi-icon icon="mdiTagOutline" class="hw-7" />
                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Tags
                    </div>
                </div>
            </router-link>

            <!-- Price Groups -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'price-groups' }" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{ 'lsn-side-primary-menu__item--active': isActive }" @click="navigate">
                    <mdi-icon icon="mdiCurrencyUsd" class="hw-7" />
                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Price Groups
                    </div>
                </div>
            </router-link>

            <!-- Tools -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'tools' }" custom>
                <div class="lsn-side-primary-menu__item lsn-tooltip lsn-tooltip--arrow lsn-tooltip--right" :class="{ 'lsn-side-primary-menu__item--active': isActive }" @click="navigate">
                    <mdi-icon icon="mdiTools" class="hw-7" />

                    <div class="lsn-tooltip__content bg-black py-2 px-3">
                        Tools
                    </div>
                </div>
            </router-link>

            <!-- Bottom Menu Items -->
            <div class="absolute bottom-0 w-full">

                <!-- add bottom menu here -->

                <!-- Account Menu -->
                <account-floating-menu />

            </div>

        </div>

        <!-- App Main Area -->
        <router-view />

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import AccountFloatingMenu from './AccountFloatingMenu.vue';

export default
{
    name: 'AppLayout',

    components:
    {
        AccountFloatingMenu,
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.app-layout__wrapper
{
    @apply absolute left-0 top-0 w-full;
}

</style>
