<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-card">
        <div class="cs-card__wrapper">
            <div v-if="$slots['header']" class="cs-card__header">
                <slot name="header" />
            </div>

            <div class="cs-card__body">
                <slot />
            </div>

            <div v-if="$slots['footer']" class="cs-card__footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'CsCard',
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
