<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-article-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div>Edit Article Tags</div>

            <div class="flex-grow"></div>

            <div v-if="loading || currentRequests">
                <img src="/img/loader-5.gif" alt="..." class="inline w-4 h-4">
            </div>
        </header>

        <!-- Form -->
        <perfect-scrollbar class="cs-sider__content p-4">
            <div v-if="!loading">
                <cs-checkbox
                    v-for="tag in sortedTags"
                    :key="tag.id"
                    class="mb-2"
                    :label="tag.label"
                    name="tag"
                    :value="tag.id"
                    :is-checked="articleHasTag(tag)"
                    @check="saveTagStatus($event)"
                />
            </div>

            <div v-else class="p-4">
                <img src="/img/loader-761.svg" alt="Loading..." class="mx-auto">
            </div>
        </perfect-scrollbar>

        <p v-if="errorMessage" class="lsn-form__error m-4">
            {{ errorMessage }}
        </p>

        <!-- <div class="cs-sider__spacer"></div> -->

        <div class="cs-sider__actions">
            <div class="lsn-btn lsn-btn--primary-outline" @click="close">
                Close
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { sortBy } from 'lodash-es';
import axios      from '@/axios';
import CsCheckbox from '@/components/CsCheckbox.vue';

export default
{
    name: 'ArticleTagsForm',

    components:
    {
        CsCheckbox,
    },

    data()
    {
        return {
            loading:         false,
            currentRequests: 0,
            errorMessage:    '',
        };
    },

    computed:
    {
        /**
         * All existing tags.
         */
        tags()
        {
            return this.$store.state.tags.all.filter(i => i);
        },

        /**
         * All existing tags, with those attached to the current article at the top.
         */
        sortedTags()
        {
            return sortBy(this.tags, t => !this.articleHasTag(t));
        },

        /**
         * Tags attached to the current article.
         */
        articleTags()
        {
            return this.$store.state.articles.cArticle.tags;
        },
    },

    methods:
    {
        articleHasTag(tag)
        {
            return !!this.articleTags.find(t => t.id === tag.id);
        },

        saveTagStatus({ component, value: tagId, checked })
        {
            component.disabled = true;
            this.currentRequests++;

            const articleId = this.$route.params.article_id;
            const request = {
                url:    `/api/article/${articleId}/tag/${tagId}`,
                method: checked ? 'post' : 'delete',
            };

            axios(request)
                .then(response =>
                {
                    // Clear error message
                    this.errorMessage = '';

                    // Update the article in the store
                    this.$store.dispatch('articles/updateInArticlesList', response.data);
                    this.$store.dispatch('articles/setCurrentArticle', response.data);
                })
                .catch(error =>
                {
                    this.errorMessage = error;

                    // Revert checkbox value
                    component.checked = !checked;
                })
                .then(() =>
                {
                    this.currentRequests--;
                    component.disabled = false;
                });
        },

        close()
        {
            this.$router.push(
                {
                    name: 'view-article',
                    params:
                    {
                        article_id: this.$route.params.article_id,
                    },
                }
            );
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
