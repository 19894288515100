<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-article-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div v-if="article.id">
                Edit Article
            </div>

            <div v-else>
                New Article
            </div>

            <div class="flex-grow"></div>

            <div v-if="loading">
                <img src="/img/loader-5.gif" alt="..." class="inline w-4 h-4">
            </div>

            <div v-else-if="isDirty" class="cursor-default text-4xl" title="Unsaved changes">
                &bull;
            </div>
        </header>

        <!-- Form -->
        <div class="cs-sider__content p-4">
            <lsn-select
                v-model="article.type"
                class="w-1/2 lsn-form__wrapper--padless mb-4"
                label="Type"
                ckey="type"
                :options="types"
                ovalue="code"
                :error="errors.type"
            />

            <lsn-input
                v-model="article.label"
                class="lsn-form__wrapper--padless mb-4"
                label="Label"
                ckey="label"
                :error="errors.label"
            />

            <lsn-input
                v-model="article.code"
                class="lsn-form__wrapper--padless mb-4"
                label="Code"
                ckey="code"
                :error="errors.code"
                :is-locked="!!article.id"
                lock-text="The code cannot be updated because it is used to make a link with other applications."
            />

            <lsn-input
                v-model="article.article_class"
                class="lsn-form__wrapper--padless mb-4"
                label="Article Class"
                ckey="article_class"
                :error="errors.article_class"
            />

            <lsn-input
                v-model="article.order_class"
                class="lsn-form__wrapper--padless mb-4"
                label="Order Class"
                ckey="order_class"
                :error="errors.order_class"
            />

            <lsn-select
                v-model="article.status"
                class="w-1/2 lsn-form__wrapper--padless mb-4"
                label="Status"
                ckey="status"
                :options="statuses"
                ovalue="code"
                :error="errors.status"
            />

            <lsn-input-number
                v-model="article.photo_id"
                class="lsn-form__wrapper--padless mb-4"
                label="Photo ID"
                ckey="photo_id"
                :error="errors.photo_id"
            />

            <lsn-textarea
                v-model="article.icom"
                class="lsn-form__wrapper--padless mb-4"
                label="Internal Comment"
                ckey="icom"
                :error="errors.icom"
            />

            <p v-if="errorMessage" class="lsn-form__error mb-4">
                {{ errorMessage }}
            </p>

        </div>

        <div class="cs-sider__actions">
            <div class="lsn-field-wrapper">
                <div class="lsn-btn lsn-btn--primary-outline" @click="cancel">
                    Cancel
                </div>
            </div>

            <div class="lsn-field-wrapper ml-3">
                <div class="lsn-btn" :class="isDirty && !loading ? 'lsn-btn--primary' : 'lsn-btn--disabled'" @click="save">
                    Save
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { clone, isEqual } from 'lodash-es';

const defaultArticle = {
    id:            0,
    type:          '',
    code:          '',
    label:         '',
    status:        '',
    article_class: '',
    order_class:   '',
    icom:          '',
};

export default
{
    name: 'ArticleForm',

    data()
    {
        return {
            article:      null,
            loading:      false,
            errorMessage: '',
            errors:       {},
        };
    },

    computed:
    {
        refArticle()
        {
            const articleId = parseInt(this.$route.params.article_id);

            return this.$store.getters['articles/oneById'](articleId);
        },

        types()
        {
            return [
                {
                    label: 'Article',
                    code:  this.$const.articles.type.ARTICLE,
                },
                {
                    label: 'Supplement',
                    code:  this.$const.articles.type.SUPPLEMENT,
                },
                {
                    label: 'Pack',
                    code:  this.$const.articles.type.PACK,
                },
            ];
        },

        statuses()
        {
            return [
                {
                    label: 'Active',
                    code:  this.$const.articles.status.ACTIVE,
                },
                {
                    label: 'Reorder only',
                    code:  this.$const.articles.status.REORDER_ONLY,
                },
                {
                    label: 'Inactive',
                    code:  this.$const.articles.status.INACTIVE,
                },
            ];
        },

        isDirty()
        {
            if(this.article.id)
            {
                return !isEqual(this.article, this.refArticle);
            }
            else
            {
                return !isEqual(this.article, defaultArticle);
            }
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            if(this.$route.params.article_id)
            {
                this.article = clone(this.refArticle);
            }
            else
            {
                this.article = clone(defaultArticle);
            }
        },

        cancel()
        {
            const article_id = parseInt(this.$route.params.article_id);

            if(article_id)
            {
                // Cancel editing an existing article -> back to viewing that article
                this.$router.push({ name: 'view-article', params: { article_id } });
            }
            else
            {
                // Cancel creating a new article -> back to the articles list
                this.$router.push({ name: 'articles' });
            }
        },

        save()
        {
            if(!this.isDirty || this.loading)
            {
                return;
            }

            this.loading = true;

            const action = this.article.id ? 'articles/update' : 'articles/create';
            this.$store.dispatch(action, this.article)
                .then(response =>
                {
                    this.errorMessage = '';
                    this.errors = {};

                    // Select the current article in the store
                    this.$store.dispatch('articles/setCurrentArticle', response.data);

                    // Redirect to view the created/updated article
                    this.$router.push({ name: 'view-article', params: { article_id: response.data.id } });
                })
                .catch(error =>
                {
                    if(import.meta.env.MODE !== 'production')
                    {
                        console.log('Article Form Error', error.toJSON?.() || error);
                        console.log('Article Form Error Response', error.response);
                    }

                    // Set general error message
                    if(error.response?.message)
                    {
                        this.errorMessage = error.response.message;
                    }
                    else if(error.message)
                    {
                        this.errorMessage = error.message;
                    }
                    else if(typeof error === 'string')
                    {
                        this.errorMessage = error;
                    }

                    // Set field-specific errors
                    if(error.response?.errors)
                    {
                        this.errors = error.response.errors;
                    }
                })
                .then(() =>
                {
                    this.loading = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
