import {
    mdiAccountCircleOutline,
    mdiApps,
    mdiCalendar,
    mdiCheck,
    mdiCheckboxBlankOutline,
    mdiChevronLeft,
    mdiChevronRight,
    mdiCurrencyUsd,
    mdiEyeOffOutline,
    mdiEyeOutline,
    mdiFileDownloadOutline,
    mdiFileExcelOutline,
    mdiFilter,
    mdiHanger,
    mdiHome,
    mdiLockOutline,
    mdiMenu,
    mdiPackageVariantClosedPlus,
    mdiPencil,
    mdiPlus,
    mdiTagOutline,
    mdiTools,
} from '@mdi/js';

export default Object.freeze({
    mdiAccountCircleOutline,
    mdiApps,
    mdiCalendar,
    mdiCheck,
    mdiCheckboxBlankOutline,
    mdiChevronLeft,
    mdiChevronRight,
    mdiCurrencyUsd,
    mdiEyeOffOutline,
    mdiEyeOutline,
    mdiFileDownloadOutline,
    mdiFileExcelOutline,
    mdiFilter,
    mdiHanger,
    mdiHome,
    mdiLockOutline,
    mdiMenu,
    mdiPackageVariantClosedPlus,
    mdiPencil,
    mdiPlus,
    mdiTagOutline,
    mdiTools,
});
