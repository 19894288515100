/* ===============
 * Account Module
 * ===============
 */

import axios from '@/axios';

export function setupAccountModule(VueUserConfigurations, VueHoneybadger)
{
    return {
        namespaced: true,

        // ------------------------------------------------------------------------- STATE

        state()
        {
            return {
                cUser:    null,  // Connected user
                cCompany: null,   // Current selected company
            };
        },

        // ------------------------------------------------------------------------- GETTERS

        getters:
        {
            getCompanyById: state => id => state.companies.find(c => c.id == id),
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            /**
             * Set connected user.
             * @param {Object} state
             * @param {User}   cUser
             */
            setCurrentUser(state, cUser)
            {
                console.log('--- CONNECTED USER:', cUser);

                state.cUser = cUser;

                VueHoneybadger.setContext({ user: state.cUser });
            },

            /**
             * Set current company.
             * @param {Object} state
             * @param {Object} company
             */
            setCurrentCompany(state, company)
            {
                console.log('--- CURRENT COMPANY:', company);

                state.cCompany = company;

                VueHoneybadger.setContext({ company: state.cCompany });
            },

            /* ---------- user configurations ---------- */

            /**
             * Set global user configurations (from app_config table).
             * @param {Object} state
             * @param {Object} configurations
             */
            setUserConfigurations(state, configurations)
            {
                VueUserConfigurations.initialize(configurations);
            },
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            /* ---------- connected user ---------- */

            fetchConnectedUser({ commit, dispatch })
            {
                return axios.get('/api/user/connected').then(response =>
                {
                    commit('setUserConfigurations', response.data.configurations);
                    delete response.data.configurations; // Avoid having configurations un cUser object.

                    commit('setCurrentUser', response.data);

                    //Listen for "set" event and update user configuration accordingly (server side).
                    VueUserConfigurations._configurations.on('set', function(key, value)
                    {
                        dispatch('updateUserConfigurations', {
                            ckey:   key,
                            cvalue: value,
                        });
                    });
                }).catch(function(error)
                {
                    console.log(error);
                });
            },

            /* ---------- company ---------- */

            fetchCurrentCompany({ state, commit })
            {
                let url = '/api/company/:code';
                url = url.replace(':code', state.cUser.company_code);

                return axios.get(url).then(response =>
                {
                    commit('setCurrentCompany', response.data);
                }).catch(function(error)
                {
                    console.log(error);
                });
            },

            /* ---------- user configurations ---------- */

            updateUserConfigurations({ commit, dispatch }, configuration)
            {
                return axios.post('/api/user-configuration', configuration).then(response =>
                {
                    // Nothing to do.
                }).catch((error) =>
                {
                    console.log(error);
                });
            },
        },
    };
};
