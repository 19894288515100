import axios from 'axios';


// ------------------------------------------------------------ INTERCEPTORS
axios.interceptors.response.use(
    response =>
    {
        if(response.data?.data)
        {
            response.meta = response.data.meta;
            response.data = response.data.data;
        }

        return response;
    },
    error =>
    {
        if(error.response?.data)
        {
            error.response.message = error.response.data.message;
            error.response.meta = error.response.data.meta;
            error.response.errors = error.response.data.errors;
            delete error.response.data;
        }

        // Log error messages in development environment
        if(import.meta.env.MODE !== 'production')
        {
            if(error.response?.message)
            {
                console.log('----- Axios Error Message', error.response.message);
            }

            if(error.response?.errors)
            {
                console.log('----- Axios Error Data', error.response.errors);
            }

            if(error.response?.meta)
            {
                console.log('----- Axios Error Meta', error.response.meta);
            }
        }

        return Promise.reject(error);
    }
);

export default axios;
