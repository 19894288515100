<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-list">
        <perfect-scrollbar class="cs-list__wrapper">
            <div
                v-for="tag in filteredTags"
                :key="tag.id"
                class="cs-list__item"
                :class="tag.id == $route.params.tag_id ? 'cs-list__item--selected' : ''"
                @click="selectTag(tag.id)"
            >
                <div class="cs-list__item-label">
                    {{ tag.label }}
                </div>

                <div class="cs-list__item-code">
                    {{ tag.code }}
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import Fuse from 'fuse.js';

export default
{
    name: 'TagsList',

    props:
    {
        searchText:
        {
            type:     String,
            required: true,
        },
    },

    data()
    {
        return {
            fuse: null,
        };
    },

    computed:
    {
        tags()
        {
            return this.$store.getters['tags/sortedTags'];
        },

        filteredTags()
        {
            let tags = this.tags;

            if(this.searchText.length >= 2)
            {
                tags = this.fuse.search(this.searchText).map(res => res.item);
            }

            return tags;
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            this.fuse = new Fuse(this.tags,
                {
                    keys:      ['label', 'code'],
                    threshold: 0.2,
                    location:  0,
                    distance:  100,
                });
        },

        /**
         * Select a tag for display & edition.
         */
        selectTag(tagId)
        {
            this.$router.push({ name: 'view-tag', params: { tag_id: tagId } });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.lsn-list
{
    @apply absolute top-0 bottom-0 left-0 right-0 border-r border-gray-300;
}

.lsn-list-item
{
    @apply px-4 py-1 border-b border-gray-300 cursor-pointer;
}

.lsn-list-item:hover
{
    @apply bg-primary-10;
}

.lsn-list-item__label
{
    @apply text-gray-500 text-sm;
}

.lsn-list-item__code
{
    @apply text-gray-400 text-xs truncate;
}

// Selected item
.cs-list__item--selected
{
    @apply bg-primary-10;
}
</style>
