<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-main-area">
        <div class="page-content">
            <export-prices-card class="tool-card" />
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ExportPricesCard from '@/components/tools/ExportPricesCard.vue';

export default
{
    name: 'Tools',

    components:
    {
        ExportPricesCard,
    },

    data()
    {
        return {
        };
    },

    computed:
    {
        arr()
        {
            return new Array(7);
        },
    },

    watch:
    {
    },

    created()
    {
    },

    methods:
    {
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.page-content
{
    @apply block p-4 w-full h-screen overflow-x-hidden overflow-y-auto;
}

.tool-card
{
    @apply float-left pr-4 pb-4 w-full lg:w-1/3 3xl:w-1/4;
}
</style>
