/* ==============
 *  Tags  Module
 * ==============
 */

import { sortBy } from 'lodash-es';
import axios      from '@/axios';

export default
{
    namespaced: true,


    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            all:          [],       // All tags
            selectedTags: [],       // Set of currently selected tags
            cTag:         null,     // Currently displayed tag
        };
    },


    // ------------------------------------------------------------------------- GETTERS

    getters:
    {
        sortedTags: state => sortBy(state.all, ['status', 'label']),

        oneById: state => id => state.all.find(t => t.id == id) || null,
    },


    // ------------------------------------------------------------------------- MUTATIONS

    mutations:
    {
        setAll(state, tags)
        {
            if(!Array.isArray(tags))
            {
                throw new TypeError('tags must be an array');
            }

            state.all = tags;
        },

        /**
         * Add a tag to tags list.
         * @param {Object} state
         * @param {Object} tag
         */
        addToTagsList(state, tag)
        {
            state.all.push(tag);
        },

        /**
         * Update a tag in tags list.
         * @param {Object} state
         * @param {Object} tag
         */
        updateInTagsList(state, tag)
        {
            const index = state.all.findIndex(t => t.id == tag.id);
            if(index === -1)
            {
                throw new Error('Tag not found');
            }

            state.all.splice(index, 1, tag);
        },

        /**
         * Set current tag.
         * @param {Object} state
         * @param {Object} tag
         */
        setTag(state, tag)
        {
            state.cTag = tag;
        },

        selectTag(state, tag)
        {
            const index = state.selectedTags.findIndex(t => t.id == tag.id);
            if(index !== -1)
            {
                throw new Error('Tag already in selection');
            }

            state.selectedTags.push(tag);
        },

        deselectTag(state, tag)
        {
            const index = state.selectedTags.findIndex(t => t.id == tag.id);
            if(index === -1)
            {
                throw new Error('Tag not found in selection');
            }

            state.selectedTags.splice(index, 1);
        },

        deselectAll(state)
        {
            state.selectedTags = [];
        },
    },


    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        /**
         * Retrieve the list of tags and store it.
         * @returns {Promise}
         */
        fetchAll({ commit })
        {
            return new Promise((resolve, reject) =>
            {
                axios.get('/api/tag')
                    .then(response =>
                    {
                        commit('setAll', response.data);
                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        fetchTag({ commit, getters }, tagId)
        {
            return new Promise((resolve, reject) =>
            {
                axios.get(`/api/tag/${tagId}`)
                    .then(response =>
                    {
                        const tag = response.data;
                        let mutation;

                        // Add to or update in tags list
                        if(getters.oneById(tag.id))
                        {
                            mutation = 'updateInTagsList';
                        }
                        else
                        {
                            mutation = 'addToTagsList';
                        }

                        commit(mutation, tag);

                        resolve(tag);
                    })
                    .catch(error =>
                    {
                        console.error(error.message);

                        reject(error);
                    });
            });
        },

        addToTagsList({ commit }, tag)
        {
            commit('addToTagsList', tag);

            return Promise.resolve(tag);
        },

        updateInTagsList({ commit }, tag)
        {
            commit('updateInTagsList', tag);

            return Promise.resolve(tag);
        },

        setCurrentTag({ commit }, tag)
        {
            commit('setTag', tag);

            return Promise.resolve(tag);
        },

        /**
         * Insert a new tag via ajax and reflect it to the store.
         * @param {{}} tag
         * @returns {Promise}
         */
        create({ commit }, tag)
        {
            return new Promise((resolve, reject) =>
            {
                axios.post('/api/tag', tag)
                    .then(response =>
                    {
                        // Save returned tag in the store
                        commit('addToTagsList', response.data);

                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        /**
         * Update an existing tag via ajax and reflect it to the store.
         * @param {{}} tag
         * @returns {Promise}
         */
        update({ commit }, tag)
        {
            return new Promise((resolve, reject) =>
            {
                axios.put(`/api/tag/${tag.id}`, tag)
                    .then(response =>
                    {
                        // Save returned tag in the store
                        commit('updateInTagsList', response.data);

                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        toggleSelectedTag({ commit }, { tag, checked })
        {
            return new Promise((resolve, reject) =>
            {
                const mutation = (checked ? 'selectTag' : 'deselectTag');
                commit(mutation, tag);
                resolve(tag);
            });
        },

        deselectAll({ commit })
        {
            commit('deselectAll');
            return Promise.resolve();
        },

        setAll({ commit }, tags)
        {
            return new Promise((resolve, reject) =>
            {
                commit('setAll', tags);
                resolve(tags);
            });
        },
    },
};
