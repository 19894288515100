const Strings =
{
    /**
     * Uppercase the first character of a string and lowercase the rest.
     *
     * @param {String} str The string to capitalize.
     * @returns {String}
     */
    capitalize(str = '')
    {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    /**
     * Uppercase the first character of each word of a string and lowercase the rest.
     *
     * @param {String} str       The string to capitalize.
     * @param {String} separator The word separator. Defaults to a space.
     * @returns {String}
     */
    capitalizeWords(str, separator = ' ')
    {
        str = Strings.getSafeString(str);

        return str.split(separator).map(s => Strings.capitalize(s)).join(' ');
    },

    /**
     * Convert undefined and null values to an empty string, or any other value to its string representation.
     * @param {String} str
     * @returns {String}
     */
    getSafeString(str)
    {
        if(typeof str === 'undefined' || str === null)
        {
            return '';
        }

        if(typeof str !== 'string')
        {
            return str + '';
        }

        return str;
    },
};


export default Strings;
