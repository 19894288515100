/*******************
 * Main Vuex Store *
 ******************/

import axios                  from '@/axios';
import { createStore }        from 'vuex';
import AjaxStack              from 'ajaxstack';

// Modules
import { setupAccountModule } from './account';
import articles               from './articles';
import tags                   from './tags';
import priceGroups            from './price-groups';
import articlePermissions     from './article-permissions';
import { setupAuthModule }    from './auth';

// Actions called during application initialization
const initializationActions = [
    { action: 'articles/initialize' },
    { action: 'account/fetchCurrentCompany' },
    { action: 'fetchApplicationConfigurations' },
    // {action: 'auth/autoRefreshAccessToken'},
    { action: 'auth/checkIdle' },
];

export function setupStore(VueApplicationConfigurations, VueUserConfigurations, VueHoneybadger)
{
    const store = createStore({

        /**
         * Assign the modules to the store.
         */
        modules:
        {
            account: setupAccountModule(VueUserConfigurations, VueHoneybadger),
            auth:    setupAuthModule(VueUserConfigurations),
            articles,
            tags,
            priceGroups,
            articlePermissions,
        },

        // ------------------------------------------------------------------------- STATE

        state()
        {
            return {
                $route: null,
            };
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            /**
             * Store the current route to make it available to router actions & mutations.
             * This is called from the first navigation guard in `@/router/index.js`.
             * @param {{}} state
             * @param {{}} route
             */
            setCurrentRoute(state, route)
            {
                state.$route = route;
            },


            /* ---------- application configurations ---------- */

            /**
             * Set global application configurations (from app_config table).
             * @param {Object} state
             * @param {Object} configurations
             */
            setApplicationConfigurations(state, configurations)
            {
                VueApplicationConfigurations.initialize(configurations);
            },
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            initialize({ dispatch })
            {
                return dispatch('account/fetchConnectedUser').then(() =>
                {
                    let initializationAjaxStack = new AjaxStack(initializationActions, dispatch, { concurrency: 3 });

                    return initializationAjaxStack.run({});
                });
            },


            /* ---------- application configurations ---------- */

            fetchApplicationConfigurations({ commit })
            {
                return axios.get('/api/application-configurations').then(response =>
                {
                    commit('setApplicationConfigurations', response.data);
                }).catch((error) =>
                {
                    console.log(error);
                });
            },
        },
    });

    return store;
};
