import Honeybadger from '@honeybadger-io/js';

export function createHoneybadger(options)
{
    return new VueHoneybadger(options);
}

export class VueHoneybadger
{
    constructor(options = {})
    {
        this._honeybadger = Honeybadger.configure(options);
    }

    install(app)
    {
        app.config.globalProperties.$honeybadger = this._honeybadger;

        const chainedErrorHandler = app.config.errorHandler;

        const extractContext = function(vm)
        {
            let options = typeof vm === 'function' && vm.cid != null ? vm.options : vm._isVue ? vm.$options || vm.constructor.options : vm || {};
            let name = options.name || options._componentTag;
            let file = options.__file;

            return {
                isRoot:         vm.$root === vm,
                name:           name,
                props:          options.propsData,
                parentVnodeTag: options._parentVnode ? options._parentVnode.tag : undefined,
                file:           file,
            };
        };

        if(import.meta.env.MODE == 'production')
        {
            app.config.errorHandler = (error, vm, info) =>
            {
                this._honeybadger.notify(error, { context: { vm: extractContext(vm), info: info } });

                if(typeof chainedErrorHandler === 'function')
                {
                    chainedErrorHandler.call(app, error, vm, info);
                }
            };
        }
    }

    notify(error)
    {
        this._honeybadger.notify(error);
    }

    setContext(context)
    {
        this._honeybadger.setContext(context);
    }

    addBreadcrumb(message, opts)
    {
        this._honeybadger.addBreadcrumb(message, opts);
    }

    clear()
    {
        this._honeybadger.clear();
    }

    getVersion()
    {
        this._honeybadger.getVersion();
    }
};
