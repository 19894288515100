<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <td
        class="cs-price-table__cell cs-price-table__data-cell"
        :class="cssClass"
        @click.left="cellClicked"
        @click.right="cellRightClicked"
        @dblclick="cellDoubleClicked"
    >
        <template v-if="!isEditing">
            <div class="flex justify-between items-center w-full h-full">
                <!-- Visibility (to all, or custom) -->
                <div
                    v-if="!isPriceVisibleToAll"
                    class="cs-price-table__status"
                    :title="statuses"
                >
                    <mdi-icon icon="mdiEyeOutline" />
                </div>

                <!-- Has Custom Permissions -->
                <div
                    v-if="articlePrice.permission"
                    class="cs-price-table__permission ml-1"
                    :title="permissions"
                >
                    <mdi-icon icon="mdiLockOutline" />
                </div>


                <div class="flex-grow"></div>

                <!-- Price Display -->
                <div class="cs-price-table__price">
                    {{ price }}
                </div>
            </div>
        </template>

        <template v-else>
            <!-- Price Editor -->
            <input
                ref="inputPrice"
                v-model="inputPrice"
                class="cs-price-table__input--text cs-price-table__input--price"
                :disabled="isSaving"
                @keyup.esc="cancelEdit"
                @keyup.enter="commitEdit"
                @blur="commitEdit"
                @beforeinput="validateInput"
            >
        </template>
    </td>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import {
    clone,
} from 'lodash-es';
import constants           from '@/constants/constants';
import ArticlePriceService from '@/services/ArticlePriceService';
import Strings             from '@/utils/strings';


const ARTICLE_PRICE_VISIBILITIES = [
    constants.articles.visibility.ALL,
    constants.articles.visibility.EXTERNAL_STAFF,
    constants.articles.visibility.INTERNAL_STAFF,
    constants.articles.visibility.DEVELOPER,
];

const ARTICLE_PRICE_STATUSES = constants.articlePrices.binStatus;

export default
{
    name: 'PriceTableDataCell',

    props:
    {
        priceGroupId:
        {
            type:    Number,
            default: -1,
        },

        articleId:
        {
            type:    Number,
            default: -1,
        },
    },

    emits: [
        'startEditing',
        'stopEditing',
        'openContextMenu',
    ],

    data()
    {
        return {
            isEditing:              false,
            isSaving:               false,
            isError:                false,
            currentPrice:           '',
            inputPrice:             '',
            currentPriceVisibility: '',
        };
    },

    computed:
    {
        articlePrice()
        {
            return this.$store.getters['priceGroups/latestArticlePriceByForeignKeys'](
                {
                    priceGroupId: this.priceGroupId,
                    articleId:    this.articleId,
                }
            );
        },

        /**
         * Whether a `save` action should update or create a price.
         *
         * @returns {Boolean} `true` if should update, `false` if should create.
         */
        shouldUpdate()
        {
            return this.articlePrice.valid_from === this.$store.getters['priceGroups/selectedDate'];
        },

        isCellSelected()
        {
            return this.$store.getters['priceGroups/isCellSelected'](
                {
                    priceGroupId: this.priceGroupId,
                    articleId:    this.articleId,
                }
            );
        },

        price()
        {
            const fPrice = parseFloat(this.articlePrice.price);
            if(isNaN(fPrice))
            {
                return '';
            }

            return fPrice.toFixed(2);
        },

        isPriceVisibleToAll()
        {
            return typeof this.articlePrice.bin_status === 'undefined' ||
                this.articlePrice.bin_status === constants.articlePrices.binStatus.STATUS_ACTIVE__ALL;
        },

        allStatuses()
        {
            return ARTICLE_PRICE_STATUSES;
        },

        cssClass()
        {
            return {
                'cs-price-table__data-cell--selected':                this.isCellSelected,
                'cs-price-table__data-cell--editing':                 this.isEditing,
                'cs-price-table__data-cell--saving':                  this.isSaving,
                'cs-price-table__data-cell--disabled':                this.isInThePast,
                'cs-price-table__data-cell--error':                   this.isError && !this.isSaving, // don't show error while saving
                'cs-price-table__data-cell--valid-from-current-date': this.articlePrice.valid_from === this.$store.getters['priceGroups/selectedDate'],
            };
        },

        isInThePast()
        {
            const selectedDate = new Date(this.$store.getters['priceGroups/selectedDate']);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return selectedDate < today;
        },

        /**
         * List of statuses assigned to the current article price.
         *
         * @returns {String}
         */
        statuses()
        {
            const statuses = Object.entries(ArticlePriceService.getBinStatusDebugInfo(this.articlePrice.bin_status).visibilities);

            return statuses
                .map(([role, visibility]) =>
                {
                    const v = {
                        'ACTIVE':       '✅',
                        'REORDER_ONLY': '🔄',
                        'INACTIVE':     '⛔️',
                    }[visibility];

                    return v + ' ' + Strings.capitalizeWords(role, '_').replace('All', 'Customer');
                })
                .reverse()
                .join('\n\n');
        },

        /**
         * List of permissions assigned to the current article price.
         *
         * @returns {String}
         */
        permissions()
        {
            const out = [];

            for(const perm of this.$store.state.articlePermissions.all)
            {
                if(this.articlePrice.permission & perm.bin)
                {
                    out.push(perm.label);
                }
            }

            return out.join(', ');
        },
    },

    watch:
    {
        priceGroupId(pgid)
        {
            console.log('priceGroupId', pgid);
        },

        articleId(aid)
        {
            console.log('articleId', aid);
        },
    },

    methods:
    {
        cellClicked(event)
        {
            // Don't do anything special if we're editing or saving
            if(this.isEditing || this.isSaving)
            {
                return;
            }

            this.manageSelection(event);
        },

        /**
         * Handler for right-click events.
         * @param {MouseEvent} event
         */
        cellRightClicked(event)
        {
            // Don't do anything if we're editing or saving
            if(this.isEditing || this.isSaving)
            {
                return;
            }

            // Prevent default context menu
            event.preventDefault();

            // Don't do anything if there isn't an existing price for this cell, or if we're viewing a past/locked price
            if(!this.articlePrice.id || this.isInThePast)
            {
                return;
            }

            // Select the cell if it isn't already
            const cell = {
                priceGroupId: this.priceGroupId,
                articleId:    this.articleId,
            };
            if(!this.$store.getters['priceGroups/isCellSelected'](cell))
            {
                this.manageSelection(event);
            }

            // Open the context menu
            this.$emit('openContextMenu',
                {
                    cell: this, // the current component
                    event,
                }
            );
        },

        cellDoubleClicked()
        {
            // Don't do anything if we're editing, saving, or in the past
            if(this.isSaving || this.isEditing || this.isInThePast)
            {
                return;
            }

            // Deselect all cells...
            this.$store.dispatch('priceGroups/deselectAllCells')
                .then(() =>
                {
                    // Then toggle the editor
                    this.startEditing();
                });
        },

        /**
         * Analyze click events and current state to determine what to do with the selection.
         * @param {MouseEvent} event
         */
        manageSelection(event)
        {
            const cell = {
                priceGroupId: this.priceGroupId,
                articleId:    this.articleId,
            };

            // Toggle the cell's "selected" status
            if(this.$store.getters['priceGroups/isCellSelected'](cell))
            {
                this.$store.dispatch('priceGroups/deselectCell', cell);
            }
            else
            {
                // Deselect all cells...
                this.$store.dispatch('priceGroups/deselectAllCells')
                    .then(() =>
                    {
                        // Select the one we clicked
                        this.$store.dispatch('priceGroups/selectCell', cell);
                    });
            }
        },

        startEditing()
        {
            if(this.isEditing || this.isSaving || this.isInThePast)
            {
                return;
            }

            // Memorize current price (in case we want to cancel)
            this.currentPrice = this.price;

            // Set up and open the editor
            this.inputPrice = this.price;
            this.isEditing = true;
            this.$nextTick(() =>
            {
                this.$refs.inputPrice.select(); // focus and select the whole input price
                this.$emit('startEditing');
            });
        },

        commitEdit()
        {
            // Ensure we are in edit mode, and not currently saving
            if(!this.isEditing || this.isSaving || this.isInThePast)
            {
                return;
            }

            // Don't save if nothing changed
            if(this.currentPrice == this.inputPrice)
            {
                this.cancelEdit();
                return;
            }

            // Prepare save request
            let storeAction;
            let data;
            if(!this.shouldUpdate)
            {
                // Create new article price
                storeAction = 'priceGroups/createArticlePrice';
                data = clone(this.articlePrice); // reuse existing fields, if any (bin_status, etc.)

                // Ensure mandatory fields have correct values
                data.price_group_id = this.priceGroupId;
                data.article_id     = this.articleId;
                data.valid_from     = this.$store.getters['priceGroups/selectedDate'];
                data.price          = this.inputPrice;
            }
            else
            {
                // Update existing article price
                storeAction = 'priceGroups/updateArticlePrice';
                data = {
                    id:         this.articlePrice.id,
                    price:      this.inputPrice,
                    valid_from: this.$store.getters['priceGroups/selectedDate'],
                };
            }

            // Perform the save request
            this.isSaving = true;
            this.$store.dispatch(storeAction, data)
                .then(articlePrice =>
                {
                    // Update component state
                    this.currentPrice = articlePrice.price;
                    this.isError = false;
                    this.stopEditing();
                })
                .catch(error =>
                {
                    this.handleError(error, storeAction);
                })
                .then(() =>
                {
                    this.isSaving = false;
                });
        },

        cancelEdit()
        {
            // Ensure we are in edit mode, and not currently saving
            if(!this.isEditing || this.isSaving)
            {
                return;
            }

            // Rollback edit, then stop editing
            this.inputPrice = this.currentPrice;
            this.isError = false;
            this.stopEditing();
        },

        stopEditing()
        {
            this.isEditing = false;
            this.$emit('stopEditing');
        },

        closePrice()
        {
            this.isSaving = true;
            this.$store.dispatch('priceGroups/closeArticlePrice', this.articlePrice.id)
                .then(articlePrice =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    this.handleError(error, 'closePrice');
                })
                .then(() =>
                {
                    this.isSaving = false;
                });
        },

        deletePrice()
        {
            this.isSaving = true;

            const priceGroup = this.$store.getters['priceGroups/oneById'](this.articlePrice.price_group_id);
            const article    = this.$store.getters['articles/oneById'](this.articlePrice.article_id);
            const price      = new Intl.NumberFormat('fr-CH', { style: 'currency', currency: priceGroup.currency_code }).format(this.articlePrice.price);

            const message = `Do you really want to delete this price?

Price Group:
    ${priceGroup.label}
Article:
    ${article.label}
Price:
    ${price}`;

            if(!confirm(message))
            {
                this.isSaving = false;

                return;
            }

            this.$store.dispatch('priceGroups/deleteArticlePrice', this.articlePrice.id)
                .then(articlePrice =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    this.handleError(error, 'deletePrice');
                })
                .then(() =>
                {
                    this.isSaving = false;
                });

        },

        validateInput(event)
        {
            /** @type {String} */
            const input = event.data;

            // Since inputs can be typed or pasted, it's best to check all use cases separately.
            // Only allow the following inputs:
            if(input &&
                !/^\d*\.?\d+$/.test(input) &&                           // strings that are positive float numbers, e.g. "10", "2.0", "0.3", ".4", "567.89"
                !(input === '.' && !event.target.value.includes('.')))  // dot character ("."), if it's not already present in the field
            {
                event.preventDefault();
                event.stopPropagation();

                return false;
            }
        },

        handleError(error, context = '')
        {
            // TODO: Display error message properly! (tooltip?)
            console.log(context, 'ERROR', error.response?.errors || error.message || error);

            // BUG: Re-focus element after error (this code doesn't work)
            this.$nextTick(() =>
            {
                this.$refs.inputPrice.select();
            });
            this.isError = true;
        },

        savePriceStatus(binStatus)
        {
            const _this = this;

            return new Promise((resolve, reject) =>
            {
                if(binStatus >= 2 ** 32)
                {
                    throw new RangeError(`Status overflows 32 bits. Got ${binStatus}.`);
                }
                if(binStatus < 0)
                {
                    throw new RangeError(`Status cannot be negative. Got ${binStatus}.`);
                }

                _this.isSaving = true;

                let storeAction;
                let data;
                if(!_this.shouldUpdate)
                {
                    // Copy the current price (create a new one at the selected date, with the selected status)
                    storeAction = 'priceGroups/createArticlePrice';
                    data = clone(_this.articlePrice);
                    data.valid_from = _this.$store.getters['priceGroups/selectedDate'];
                    data.bin_status ^= binStatus; // XOR toggles existing status
                }
                else
                {
                    // Update the status of the current price
                    storeAction = 'priceGroups/updateArticlePrice';
                    data = {
                        id:         _this.articlePrice.id,
                        valid_from: _this.$store.getters['priceGroups/selectedDate'],
                        bin_status: _this.articlePrice.bin_status ^ binStatus, // XOR toggles existing status
                    };
                }

                // Perform the save request (create/update)
                _this.$store.dispatch(storeAction, data)
                    .then(articlePrice =>
                    {
                        _this.isError = false;

                        resolve(articlePrice);
                    })
                    .catch(error =>
                    {
                        // TODO: Display error message properly! (tooltip?)
                        console.log('savePriceStatus() ERROR', error.response?.errors || error.message || error);
                        _this.isError = true;

                        reject(error);
                    })
                    .then(() =>
                    {
                        _this.isSaving = false;
                    });
            });
        },

        savePricePermission(permission)
        {
            const _this = this;

            return new Promise((resolve, reject) =>
            {
                if(permission >= 2 ** 32)
                {
                    throw new RangeError(`Permission overflows 32 bits. Got ${permission}.`);
                }
                if(permission < 0)
                {
                    throw new RangeError(`Permission cannot be negative. Got ${permission}.`);
                }

                _this.isSaving = true;

                let storeAction;
                let data;
                if(!_this.shouldUpdate)
                {
                    // Copy the current price (create a new one at the selected date, with the selected permission)
                    storeAction = 'priceGroups/createArticlePrice';
                    data = clone(_this.articlePrice);
                    data.valid_from = _this.$store.getters['priceGroups/selectedDate'];
                    data.permission ^= permission; // XOR toggles existing permission
                }
                else
                {
                    // Update the permission of the current price
                    storeAction = 'priceGroups/updateArticlePrice';
                    data = {
                        id:         _this.articlePrice.id,
                        valid_from: _this.$store.getters['priceGroups/selectedDate'],
                        permission: _this.articlePrice.permission ^ permission, // XOR toggles existing permission
                    };
                }

                // Perform the save request (create/update)
                _this.$store.dispatch(storeAction, data)
                    .then(articlePrice =>
                    {
                        _this.isError = false;

                        resolve(articlePrice);
                    })
                    .catch(error =>
                    {
                        // TODO: Display error message properly! (tooltip?)
                        console.log('savePriceStatus() ERROR', error.response?.errors || error.message || error);
                        _this.isError = true;

                        reject(error);
                    })
                    .then(() =>
                    {
                        _this.isSaving = false;
                    });
            });
        },

        // fixme: Create method to decompose bin_status to a status text
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
