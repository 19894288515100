/* ======================
 *  Price Groups  Module
 * ======================
 */

import moment    from 'moment';
import axios     from '@/axios';
import constants from '@/constants/constants';

function compareCells(cell1, cell2)
{
    return cell1.articleId == cell2.articleId && cell1.priceGroupId == cell2.priceGroupId;
}

export default
{
    namespaced: true,


    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            all:           [], // All price groups
            selectedCells: [], // Table cells which are currently selected
            articlePrices: {}, // All article prices hierarchy -> ArticlePrices{ Price Group ID: { Article ID: [ Article Prices... ] } }
            changeDates:   [], // Dates at which prices have changed, i.e. all distinct `valid_from` dates, ordered with the oldest first.
        };
    },


    // ------------------------------------------------------------------------- GETTERS

    getters:
    {
        // ---------------------------------------- Price Groups

        oneById: state => id => state.all.find(pg => pg.id === id) || null,

        /**
         * The IDs of the currently displayed price groups, retrieved from route params.
         * @param {{}} state
         * @param {{}} getters
         * @param {{}} rootState
         * @returns {Array}
         */
        selectedIds(state, getters, rootState)
        {
            const price_groups = rootState.$route.params.price_groups;
            if(!price_groups)
            {
                return [];
            }

            return price_groups.split('-').filter(id => id !== '').map(id => parseInt(id));
        },

        /**
         * The currently displayed price groups, retrieved from route params.
         * @param {{}} state
         * @param {{}} getters
         * @returns {Array}
         */
        selected(state, getters)
        {
            return getters.selectedIds.map(id => getters.oneById(id));
        },

        currencies(state)
        {
            return [...new Set(state.all.map(pg => pg.currency_code).sort())];
        },


        // ---------------------------------------- Price Table Cells


        isCellSelected: state => cell =>
        {
            return state.selectedCells.findIndex(c => compareCells(c, cell)) !== -1;
        },


        // ---------------------------------------- Article Prices

        latestArticlePriceByForeignKeys: state => ({ priceGroupId, articleId }) =>
        {
            // Return the latest price (or an empty object if none)
            return state.articlePrices[priceGroupId]?.[articleId]?.[0] || {};
        },

        selectedDate(state, getters, rootState)
        {
            return rootState.$route.params.date || moment().format('YYYY-MM-DD');
        },
    },


    // ------------------------------------------------------------------------- MUTATIONS

    mutations:
    {
        // ---------------------------------------- Price Groups

        setAll(state, priceGroups)
        {
            state.all = priceGroups;
        },


        // ---------------------------------------- Price Table Cells

        setSelectedCells(state, selection)
        {
            if(!Array.isArray(selection))
            {
                throw new TypeError('Selection must be an array');
            }

            state.selectedCells = selection;
        },

        selectCell(state, cell)
        {
            const cellIndex = state.selectedCells.findIndex(c => compareCells(c, cell));
            if(cellIndex !== -1 && constants.app.DEBUG_MODE)
            {
                console.warn(`Cell {${cell.priceGroupId}, ${cell.articleId}} cannot be selected because it already is.`);
            }
            else
            {
                state.selectedCells.push(cell);
            }
        },

        deselectCell(state, cell)
        {
            const cellIndex = state.selectedCells.findIndex(c => compareCells(c, cell));
            if(cellIndex === -1 && constants.app.DEBUG_MODE)
            {
                console.warn(`Cell {${cell.priceGroupId}, ${cell.articleId}} cannott be deselected because it wasn't already.`);
            }

            state.selectedCells.splice(cellIndex, 1);
        },


        // ---------------------------------------- Article Prices

        setArticlePrices(state, prices)
        {
            if(typeof prices !== 'object')
            {
                throw new TypeError('Prices must be an object');
            }

            state.articlePrices = prices;
        },

        updateArticlePrice(state, articlePrice)
        {
            if(typeof articlePrice !== 'object')
            {
                throw new TypeError('Article price must be an object');
            }

            // Ensure price is already registered
            const knownArticlePrices = state.articlePrices[articlePrice.price_group_id]?.[articlePrice.article_id];
            if(!knownArticlePrices)
            {
                throw new Error(`Price list empty for {price_group_id: ${articlePrice.price_group_id}, article_id: ${articlePrice.article_id}}`);
            }

            const index = knownArticlePrices.findIndex(ap => ap.id === articlePrice.id);
            if(index === -1)
            {
                throw new Error(`Article price ${articlePrice.id} not found`);
            }

            // Update the article price in the store
            state.articlePrices[articlePrice.price_group_id][articlePrice.article_id].splice(index, 1, articlePrice);
        },

        insertArticlePrice(state, articlePrice)
        {
            if(typeof articlePrice !== 'object')
            {
                throw new TypeError('Article price must be an object');
            }

            // Ensure hierarchy exists; otherwise build it.
            // Hierarchy: ArticlePrices{ Price Group ID: { Article ID: [ Article Prices... ] } }
            if(!state.articlePrices[articlePrice.price_group_id])
            {
                state.articlePrices[articlePrice.price_group_id] = {};
            }
            if(!state.articlePrices[articlePrice.price_group_id][articlePrice.article_id])
            {
                state.articlePrices[articlePrice.price_group_id][articlePrice.article_id] = [];
            }
            const knownArticlePrices = state.articlePrices[articlePrice.price_group_id][articlePrice.article_id];

            // Ensure price isn't already registered
            const index = knownArticlePrices.findIndex(ap => ap.id === articlePrice.id);
            if(index !== -1)
            {
                throw new Error(`Article price ${articlePrice.id} already exists`);
            }

            // Add the article price to the store
            state.articlePrices[articlePrice.price_group_id][articlePrice.article_id].unshift(articlePrice);
        },

        deleteArticlePrice(state, articlePrice)
        {
            const list = state.articlePrices[articlePrice.price_group_id]?.[articlePrice.article_id];
            if(!list)
            {
                return;
            }

            // Find the article price
            const index = list.findIndex(ap => ap.id === articlePrice.id);
            if(index === -1)
            {
                return;
            }

            // Remove the article price from the store
            list.splice(index, 1);
        },


        // ---------------------------------------- Change Dates

        setChangeDates(state, dates)
        {
            state.changeDates = dates;
        },

        addChangeDate(state, date)
        {
            if(state.changeDates.includes(date))
            {
                // Only allow one occurrence of each date
                return;
            }

            state.changeDates.push(date);
            state.changeDates.sort();
        },
    },


    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        // ---------------------------------------- Price Groups

        /**
         * Retrieve the list of price groups and store it.
         * @returns {Promise}
         */
        fetchAll({ commit })
        {
            return new Promise((resolve, reject) =>
            {
                axios.get('/api/price-group')
                    .then(response =>
                    {
                        commit('setAll', response.data);

                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        console.error(error);

                        reject(error);
                    });
            });
        },

        select({ getters }, priceGroupId)
        {
            return new Promise((resolve, reject) =>
            {
                const priceGroup = getters.oneById(priceGroupId);
                if(priceGroup)
                {
                    resolve(priceGroup);
                }
                else
                {
                    reject('Price group not found');
                }
            });
        },


        // ---------------------------------------- Price Table Cells

        selectCell({ commit }, { priceGroupId, articleId })
        {
            const cell = { priceGroupId, articleId };
            commit('selectCell', cell);

            return Promise.resolve(cell);
        },

        deselectCell({ commit }, { priceGroupId, articleId })
        {
            const cell = { priceGroupId, articleId };
            commit('deselectCell', cell);

            return Promise.resolve(cell);
        },

        deselectAllCells({ commit })
        {
            commit('setSelectedCells', []);

            return Promise.resolve([]);
        },


        // ---------------------------------------- Article Prices

        fetchArticlePrices({ commit, getters, rootState }, date)
        {
            return new Promise((resolve, reject) =>
            {
                if(!getters.selectedIds.length)
                {
                    // Nothing to fetch
                    commit('setArticlePrices', {});
                    resolve({});

                    return;
                }

                const priceGroupIds = rootState.$route.params.price_groups; // e.g. "102-210-181"
                axios.get(`/api/article-price/${priceGroupIds}/prices/${date}`)
                    .then(response =>
                    {
                        commit('setArticlePrices', response.data);
                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        createArticlePrice({ dispatch }, { article_id, permission, price_group_id, price, bin_status, valid_from, visibility })
        {
            return new Promise((resolve, reject) =>
            {
                // Prepare data to be saved
                const data = {
                    article_id,
                    permission,
                    price_group_id,
                    price,
                    bin_status,
                    valid_from,
                    visibility,
                };

                if(constants.app.DEBUG_MODE)
                {
                    console.log('----- CREATING new article price', data);
                }

                // Perform the CREATE request
                axios.post('/api/article-price', data)
                    .then(response =>
                    {
                        const articlePrice = response.data;

                        if(constants.app.DEBUG_MODE)
                        {
                            console.log('--- SUCCESS! Received article price', articlePrice);
                        }

                        Promise.all([
                            // Update price in store (insert)
                            dispatch('insertLocalArticlePrice', articlePrice),

                            // Add date to the timeline
                            dispatch('addChangeDate', articlePrice.valid_from),
                        ])
                            .then(([articlePrice, date]) =>
                            {
                                resolve(articlePrice);
                            })
                            .catch(error =>
                            {
                                reject(error);
                            });
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        updateArticlePrice({ dispatch }, { id, permission, price, bin_status, valid_from, visibility })
        {
            return new Promise((resolve, reject) =>
            {
                // Prepare data to be saved
                const data = {
                    permission,
                    price,
                    bin_status,
                    valid_from,
                    visibility,
                };

                if(constants.app.DEBUG_MODE)
                {
                    console.log('----- UPDATING existing article price', data);
                }

                // Perform the UPDATE request
                axios.put(`/api/article-price/${id}`, data)
                    .then(response =>
                    {
                        const articlePrice = response.data;

                        if(constants.app.DEBUG_MODE)
                        {
                            console.log('--- SUCCESS! Received article price', articlePrice);
                        }

                        Promise.all([
                            // Update price in store (update)
                            dispatch('updateLocalArticlePrice', articlePrice),

                            // Add date to the timeline
                            dispatch('addChangeDate', articlePrice.valid_from),
                        ])
                            .then(([articlePrice, date]) =>
                            {
                                resolve(articlePrice);
                            })
                            .catch(error =>
                            {
                                reject(error);
                            });
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        updateLocalArticlePrice({ commit }, articlePrice)
        {
            return new Promise((resolve, reject) =>
            {
                commit('updateArticlePrice', articlePrice);
                resolve(articlePrice);

                return;
            });
        },

        insertLocalArticlePrice({ commit }, articlePrice)
        {
            return new Promise((resolve, reject) =>
            {
                commit('insertArticlePrice', articlePrice);
                resolve(articlePrice);

                return;
            });
        },

        closeArticlePrice({ commit }, id)
        {
            return new Promise((resolve, reject) =>
            {
                if(constants.app.DEBUG_MODE)
                {
                    console.log('----- CLOSING existing article price ID', id);
                }

                // Perform the CLOSE request
                axios.put(`/api/article-price/${id}/close`)
                    .then(({ data: articlePrice }) =>
                    {
                        if(constants.app.DEBUG_MODE)
                        {
                            console.log('--- SUCCESS! Received article price', articlePrice);
                        }

                        commit('updateArticlePrice', articlePrice);
                        resolve(articlePrice);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        deleteArticlePrice({ commit }, id)
        {
            return new Promise((resolve, reject) =>
            {
                if(constants.app.DEBUG_MODE)
                {
                    console.log('----- DELETING existing article price ID', id);
                }

                // Perform the DELETE request
                axios.delete(`/api/article-price/${id}`)
                    .then(({ data: articlePrice }) =>
                    {
                        if(constants.app.DEBUG_MODE)
                        {
                            console.log('--- SUCCESS! Received article price', articlePrice);
                        }

                        commit('deleteArticlePrice', articlePrice);
                        resolve(articlePrice);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },


        // ---------------------------------------- Change Dates
        fetchChangeDates({ commit, getters })
        {
            return new Promise((resolve, reject) =>
            {
                axios.get('/api/article-price/change-dates')
                    .then(response =>
                    {
                        commit('setChangeDates', response.data);
                        resolve(response.data);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        addChangeDate({ commit }, date)
        {
            commit('addChangeDate', date);
            return Promise.resolve(date);
        },
    },
};
